<template>

  <body class="bg-slate-900 min-h-full">

    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">

      <HeaderComponent />

      <!-- ========== MAIN CONTENT ========== -->
      <main v-if="pageCreatedDone" role="main" class="">
        <div class="grid place-items-center px-4 sm:px-6 lg:px-8">
          <div>
            <div class="max-w-lg border shadow-sm rounded-xl hover:shadow-lg transition bg-gray-800 border-gray-700 shadow-slate-700/[.7]">
              <div v-if="this.gameChosen && this.gameChosen['images'].length > 0" class="bg-gray-700 rounded-t-xl">
                <div v-if="!this.choosingGameInProcess">
                  <div v-for="(image, index) in this.gameChosen['images']" :key="index" class="carousel-item relative" v-show="currentGameImageShown === index">
                    <button @click="prevGameImageShown" type="button" class="absolute bottom-2 left-2 inline-flex flex-shrink-0 justify-center items-center opacity-60 gap-2 h-[3rem] w-[3rem] rounded-full border border-transparent bg-primary text-white hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-lg font-bold focus:ring-offset-gray-800">
                      <font-awesome-icon icon="fa-solid fa-chevron-left" />
                    </button>
                    <button @click="prevGameImageShown" type="button" class="absolute bottom-2 right-2 inline-flex flex-shrink-0 justify-center items-center opacity-60 gap-2 h-[3rem] w-[3rem] rounded-full border border-transparent bg-primary text-white hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-lg font-bold focus:ring-offset-gray-800">
                      <font-awesome-icon icon="fa-solid fa-chevron-right" />
                    </button>
                    <img class="w-full h-auto aspect-video rounded-t-xl object-contain" :src="image" alt="An image showing the game">
                  </div>
                </div>
                <div v-else class="w-full h-auto aspect-video rounded-t-xl border-2 border-green-500">
                  <div>&nbsp;</div>
                  <div class="inline-block p-16 animate-spin w-6 h-6 border-[3px] border-current border-t-transparent text-primary rounded-full" role="status" aria-label="loading"></div>
                </div>
              </div>
              <div v-else>
                <img class="w-full h-auto aspect-video rounded-t-xl" src="../assets/mystery-game.png" alt="Mystery placeholder box for the game">
              </div>
              <div class="px-4 md:px-5">
                <div>
                  <div v-if="this.gameChosen && this.gameChosen['title']" >
                    <div class="mt-3 text-xl font-bold text-white h-12">
                      {{ this.gameChosen['title'] }}  
                      <font-awesome-icon 
                        @click="viewGameDetailsPage(this.gameChosen['xboxApiId'], this.gameChosen['title'])" 
                        class="text-xs text-primary cursor-pointer" 
                        icon="fa-solid fa-magnifying-glass-plus" />
                    </div>
                    <div v-if="gameChosen['leavingSoon']" class="text-primary font-bold underline">
                      Leaving {{ this.gameChosen['leavingSoonDate'] }}
                    </div>
                    <div v-if="isRecentlyReleased(gameChosen)" class="text-xs text-primary font-bold underline pb-1">
                      Just Released
                    </div>
                    <div class="flex justify-between text-white text-left">
                      <span class="font-medium py-2">IGN Score</span>
                      <div class="ign-score-container">
                        <img src="../assets/ign-score-background.png" alt="IGN score logo which is a red hexagon">
                        <div class="ign-score">{{ this.gameChosen['ignScore'] }}</div>
                      </div>
                    </div>
                    <div class="flex justify-between text-white text-left">
                      <span class="font-medium">Gameplay Hours</span>
                      <span>{{ this.gameChosen['ignDurationHours'] }}</span>
                    </div>
                    <div class="flex justify-between text-white text-left py-2`">
                      <span class="font-medium">Genres&nbsp;</span> 
                      <span>{{ this.gameChosen['ignAllPossibleGenres'].join(', ') }}</span>
                    </div>
                    <div class="flex justify-between text-white text-left">
                      <span class="font-medium">Links&nbsp;</span>
                      <span v-if="isLargeScreen" class="mt-1">
                        <a class="hover:underline" :href="metacriticUrl" target="_blank">Metacritic</a>, 
                        <a class="hover:underline" :href="howLongToBeatUrl" target="_blank">HowLongToBeat</a>, 
                        <a class="hover:underline" :href="this.gameChosen['ignUrl']" target="_blank">IGN</a>, 
                        <a data-hs-overlay="#hs-gameprops-modal" class="cursor-pointer hover:underline" target="_blank">Game Properties</a>,
                      </span> 
                      <span v-else class="text-xs mt-1">
                        <a class="hover:underline" :href="metacriticUrl" target="_blank">Metacritic</a>, 
                        <a class="hover:underline" :href="howLongToBeatUrl" target="_blank">HowLongToBeat</a>, 
                        <a class="hover:underline" :href="this.gameChosen['ignUrl']" target="_blank">IGN</a>, 
                        <a data-hs-overlay="#hs-gameprops-modal" class="cursor-pointer hover:underline" target="_blank">Game Properties</a>,
                        <a class="hover:underline" :href="this.gameChosen['microsoftStoreUrl']" target="_blank">
                          Save to <font-awesome-icon 
                            class="text-white px-1 hover:text-green-300 cursor-pointer" 
                            :icon="['fab', 'xbox']" 
                          />
                        </a> |
                        <a class="hover:underline" :href="this.gameChosen['youtubeReviewUrl']" target="_blank">Review</a>,
                        <a class="hover:underline" :href="this.gameChosen['youtubeTrailerUrl']" target="_blank">Trailer</a>,
                        <a class="hover:underline" :href="this.gameChosen['youtubeWalkthroughUrl']" target="_blank">Walkthrough</a>
                      </span>
                    </div>
                    <div class="flex justify-start text-white text-right pb-2">
                      <span v-if="isLargeScreen">
                        <a class="hover:underline" :href="this.gameChosen['microsoftStoreUrl']" target="_blank">
                          Save to <font-awesome-icon 
                            class="text-white px-1 hover:text-green-300 cursor-pointer" 
                            :icon="['fab', 'xbox']" 
                          />
                        </a> |
                        <a class="hover:underline" :href="this.gameChosen['youtubeReviewUrl']" target="_blank">Review Video</a>,
                        <a class="hover:underline" :href="this.gameChosen['youtubeTrailerUrl']" target="_blank">Trailer Video</a>,
                        <a class="hover:underline" :href="this.gameChosen['youtubeWalkthroughUrl']" target="_blank">Walkthrough Video</a>
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <div class="mt-3 text-xl font-bold text-white h-12">
                      <h1>Mystery Game Awaits You</h1>
                    </div>
                    <div class="flex justify-between text-white text-left">
                      <span class="font-medium py-2">IGN Score</span>
                      <div class="ign-score-container">
                        <img src="../assets/ign-score-background.png" alt="IGN score logo which is a red hexagon">
                        <div class="ign-score">?</div>
                      </div>
                    </div>
                    <div class="flex justify-between text-white text-left">
                      <span class="font-medium">Gameplay Hours</span>
                      <span>? hrs</span>
                    </div>
                    <div class="flex justify-between text-white text-left py-2">
                      <span class="font-medium">Genres&nbsp;</span> 
                      <span>?&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </div>
                    <div class="flex justify-between text-white text-left pb-5">
                      <span class="font-medium">Links&nbsp;</span> 
                      <span>?&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </div>
                  </div>
                </div>
                <div>
                  <h1>
                    <p v-if="this.gameChosen && this.gameChosen['description']" class="h-28 mt-1 pr-2 text-gray-400 overflow-auto text-left scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
                      <span class="text-white">Released in {{ this.gameChosen['originalReleaseYear'] }} - </span>
                      <span>{{ this.gameChosen['description'] }}</span>
                    </p>
                    <p v-else class="h-28 mt-1 pr-2 text-gray-400 overflow-auto text-left">
                      Select the button below to pick a random game from the full Xbox Game Pass selection (Console/PC).
                    </p>
                  </h1>
                </div>
                <div>
                  <div>
                    <button v-if="authenticated" type="button" :disabled="choosingGameInProcess" class="px-4 mt-4 justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-2 ring-offset-gray-800" data-hs-overlay="#hs-basic-modal">
                      <div class="flex items-center justify-center">
                        <span>
                          <font-awesome-icon icon="fa-solid fa-gear" />
                        </span>
                        <span class="pl-1 text-center">
                          Picking Preferences
                        </span>
                      </div>
                      <div v-if="this.fewGamesToChooseFrom && this.numGamesToChooseFrom > 0" class="font-normal text-yellow-300 mt-2">{{ `Warning: Only ${this.numGamesToChooseFrom} games to choose from` }}</div>
                      <div v-else-if="this.fewGamesToChooseFrom && this.numGamesToChooseFrom == 0" class="font-normal text-yellow-300 mt-2">{{ `Warning: ${this.numGamesToChooseFrom} games to choose from` }}</div>
                    </button>                    
                    <button v-else type="button" @click="navigateToSignUpPage()" :disabled="choosingGameInProcess" class="px-4 mt-4 justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-2 ring-offset-gray-800" data-hs-overlay="#hs-basic-modal">
                      <div class="flex items-center">
                        <span>
                          <font-awesome-icon icon="fa-solid fa-gear" />
                        </span>
                        <span class="pl-1">
                          Picking Preferences
                        </span>
                      </div>
                    </button>
                  </div>
                  <!-- POP-UP MODAL TO ESTABLISH PICKING SETTINGS (STARTS) -->
                  <div id="hs-basic-modal" class="hs-overlay hidden w-screen h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto text-center">
                    <div class="w-screen h-full hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all p-3">
                      <div class="h-full flex flex-col border shadow-sm rounded-xl bg-gray-800 border-gray-700 shadow-slate-700/[.7]">
                        <div class="flex justify-between items-center py-3 px-4 border-b border-gray-700">
                          <span></span>
                          <h3 class="font-bold text-white">
                            <font-awesome-icon icon="fa-solid fa-gear" /> Picking Preferences
                          </h3>
                          <button type="button" class="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm focus:ring-gray-700 focus:ring-offset-gray-800" data-hs-overlay="#hs-basic-modal">
                            <span class="sr-only">Close</span>
                            <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                            </svg>
                          </button>
                        </div>
                        <div class="p-4 overflow-y-auto">
                          <p class="mt-1 text-center text-white">
                            Preferred Genre(s)
                          </p>

                          <div class="flex justify-center items-center mt-2">
                            <!-- First Label -->
                            <label
                              :class="{'text-white': !genreSwitchIsChecked, 'text-gray-500': genreSwitchIsChecked}"
                              class="mr-3 text-sm transition-colors duration-300"
                            >
                              All Genres Except...
                            </label>

                            <!-- Toggle Switch -->
                            <div @click.prevent="genreSwitchIsChecked = !genreSwitchIsChecked" class="relative">
                              <input
                                type="checkbox"
                                id="toggleSwitch"
                                class="sr-only peer w-full h-full cursor-pointer"
                                v-model="genreSwitchIsChecked"
                              />
                              <div
                                class="block bg-red-500 peer-checked:bg-green-400 w-7 h-4 rounded-full transition-colors duration-300"
                              ></div>
                              <div
                                class="dot absolute left-0.5 top-0.5 bg-white w-3 h-3 rounded-full transition-transform duration-300 ease-in-out peer-checked:transform peer-checked:translate-x-3"
                              ></div>
                            </div>

                            <!-- Second Label -->
                            <label
                              :class="{'text-white': genreSwitchIsChecked, 'text-gray-500': !genreSwitchIsChecked}"
                              class="ml-3 text-sm transition-colors duration-300"
                            >
                              All Genres Like...
                            </label>
                          </div>

                          <div class="flex justify-center py-4">
                            <div class="max-h-32 overflow-y-auto border text-sm text-center rounded-lg block p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-green-500 focus:border-green-500 scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
                              <div 
                                v-for="(genre, index) in allPossibleGenres" 
                                :key="index" 
                                :value="genre" 
                                @click="toggleGenre(genre)"
                                :class="{
                                  'bg-green-500': genresSelected.includes(genre) && genreSwitchIsChecked,
                                  'bg-red-500': genresSelected.includes(genre) && !genreSwitchIsChecked,
                                }"
                                class="cursor-pointer"
                              >
                                {{ genre }}
                              </div>
                            </div>
                          </div>

                          <p class="mt-1 text-center text-white">
                            Gameplay Length
                          </p>
                          <div class="flex flex-wrap whitespace-nowrap justify-center gap-x-6 py-4">
                            <div class="flex items-center mt-1">
                              <input 
                                v-model="gameplayLenAll" 
                                @change="gameplayLenZeroToFiveHours = gameplayLenFiveToTwentyHours = gameplayLenTwentyToFiftyHours = gameplayLenOverFiftyHours = false" 
                                type="checkbox" 
                                class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                                id="hs-checkbox-group-1">
                              <label for="hs-checkbox-group-1" class="text-sm text-gray-400 ml-3">All</label>
                            </div>

                            <div class="flex items-center mt-1">
                              <input 
                                v-model="gameplayLenZeroToFiveHours" 
                                @change="uncheckGameplayLenAll('gameplayLenZeroToFiveHours')" 
                                type="checkbox" 
                                class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                                id="hs-checkbox-group-2"
                              >
                              <label for="hs-checkbox-group-2" class="text-sm text-gray-400 ml-3">0 - 5 hours</label>
                            </div>

                            <div class="flex items-center mt-1">
                              <input 
                                v-model="gameplayLenFiveToTwentyHours" 
                                @change="uncheckGameplayLenAll('gameplayLenFiveToTwentyHours')" 
                                type="checkbox" 
                                class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                                id="hs-checkbox-group-3">
                              <label for="hs-checkbox-group-3" class="text-sm text-gray-400 ml-3">5 - 20 hours</label>
                            </div>

                            <div class="flex items-center mt-1">
                              <input 
                                v-model="gameplayLenTwentyToFiftyHours" 
                                @change="uncheckGameplayLenAll('gameplayLenTwentyToFiftyHours')" 
                                type="checkbox" 
                                class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                                id="hs-checkbox-group-4">
                              <label for="hs-checkbox-group-4" class="text-sm text-gray-400 ml-3">20 - 50 hours</label>
                            </div>

                            <div class="flex items-center mt-1">
                              <input 
                                v-model="gameplayLenOverFiftyHours" 
                                @change="uncheckGameplayLenAll('gameplayLenOverFiftyHours')" 
                                type="checkbox" 
                                class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                                id="hs-checkbox-group-5">
                              <label for="hs-checkbox-group-5" class="text-sm text-gray-400 ml-3">Over 50 hours</label>
                            </div>
                          </div>

                          <p class="mt-1 text-center text-white">
                            Game Rating (IGN Score)
                          </p>
                          <div class="flex flex-wrap whitespace-nowrap justify-center gap-x-3 py-4">
                            <div class="flex items-center mt-1">
                              <input 
                                v-model="ignScoresAll" 
                                @change="ignScoresTopRated = ignScoresWorthwhile = ignScoresSubpar = ignScoresNotRated = false" 
                                type="checkbox" 
                                class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                                id="hs-checkbox-group-6">
                              <label for="hs-checkbox-group-6" class="text-sm text-gray-400 ml-3">All</label>
                            </div>

                            <div class="flex items-center mt-1">
                              <input 
                                v-model="ignScoresTopRated"
                                @change="uncheckIgnScoresAll()" 
                                type="checkbox" 
                                class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                                id="hs-checkbox-group-7">
                              <label for="hs-checkbox-group-7" class="text-sm text-gray-400 ml-3">Top Rated (8-10)</label>
                            </div>

                            <div class="flex items-center mt-1">
                              <input 
                              v-model="ignScoresWorthwhile" 
                              @change="uncheckIgnScoresAll()" 
                              type="checkbox" 
                              class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                              id="hs-checkbox-group-8">
                              <label for="hs-checkbox-group-8" class="text-sm text-gray-400 ml-3">Worthwhile (5-8)</label>
                            </div>

                            <div class="flex items-center mt-1">
                              <input 
                              v-model="ignScoresSubpar" 
                              @change="uncheckIgnScoresAll()" 
                              type="checkbox" 
                              class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                              id="hs-checkbox-group-9">
                              <label for="hs-checkbox-group-9" class="text-sm text-gray-400 ml-3">Subpar (1-5)</label>
                            </div>

                            <div class="flex items-center mt-1">
                              <input 
                              v-model="ignScoresNotRated" 
                              @change="uncheckIgnScoresAll()" 
                              type="checkbox" 
                              class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                              id="hs-checkbox-group-10">
                              <label for="hs-checkbox-group-10" class="text-sm text-gray-400 ml-3">NR - Not Rated</label>
                            </div>
                          </div>
                          <p class="mt-1 text-center text-white">
                            Platform(s)
                          </p>
                          <div class="flex justify-center gap-x-6 py-4">
                            <div class="flex items-center mt-1">
                              <input 
                              v-model="platformAll" 
                              @change="platformXbox = platformPc = false" 
                              type="checkbox" 
                              class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                              id="hs-checkbox-group-11">
                              <label for="hs-checkbox-group-11" class="text-sm text-gray-400 ml-3">All</label>
                            </div>

                            <div class="flex items-center mt-1">
                              <input 
                              v-model="platformXbox" 

                              @change="uncheckPlatformAll('platformXbox')"
                              type="checkbox" 
                              class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                              id="hs-checkbox-group-12">
                              <label for="hs-checkbox-group-12" class="text-sm text-gray-400 ml-3">Xbox</label>
                            </div>

                            <div class="flex items-center mt-1">
                              <input 
                              v-model="platformPc" 
                              @change="uncheckPlatformAll('platformPc')" 
                              type="checkbox" 
                              class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                              id="hs-checkbox-group-13">
                              <label for="hs-checkbox-group-13" class="text-sm text-gray-400 ml-3">PC</label>
                            </div>
                          </div>

                          <p class="mt-1 text-center text-white">
                            Types of Games
                          </p>
                          <div class="flex flex-wrap justify-center gap-x-3 gap-y-2 py-4">
                            <div class="flex items-center mt-1">
                              <input 
                              v-model="typeAll" 
                              @change="typeNewReleases = typeLeavingSoon = typePotentiallyLeavingSoon = typeOther = false" 
                              type="checkbox" 
                              class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                              id="hs-checkbox-group-14">
                              <label for="hs-checkbox-group-14" class="text-sm text-gray-400 ml-3">All</label>
                            </div>

                            <div class="flex items-center mt-1">
                              <input 
                              v-model="typeNewReleases" 
                              @change="uncheckTypeAll('typeNewReleases')" 
                              type="checkbox" 
                              class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                              id="hs-checkbox-group-15">
                              <label for="hs-checkbox-group-15" class="text-sm text-gray-400 ml-3">New Releases</label>
                            </div>

                            <div class="flex items-center mt-1">
                              <input 
                              v-model="typeLeavingSoon" 
                              @change="uncheckTypeAll('typeLeavingSoon')" 
                              type="checkbox" 
                              class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                              id="hs-checkbox-group-16">
                              <label for="hs-checkbox-group-16" class="text-sm text-gray-400 ml-3">Leaving Soon</label>
                            </div>

                            <div class="flex items-center mt-1">
                              <input 
                                v-model="typePotentiallyLeavingSoon" 
                                @change="uncheckTypeAll('typePotentiallyLeavingSoon')" 
                                type="checkbox" 
                                class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                                id="hs-checkbox-group-17">
                              <label for="hs-checkbox-group-17" class="text-sm text-gray-400 ml-3">
                                Potentially Leaving Soon
                                <div class="hs-tooltip inline-block text-white text-xs">
                                  <button @mouseover="showTooltip" @mouseout="hideTooltip" type="button" class="hs-tooltip-toggle">
                                    <svg class="inline w-4 h-4 mb-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                    </svg>
                                    <span ref="tooltipContent" class="hs-tooltip-content opacity-0 transition-opacity inline-block absolute invisible z-10 w-40 py-1 px-2 text-left text-xs font-bold bg-secondary border-green-500" style="border: 1px solid green; background-color: #1a202c; color: white; border-radius: 4px;" role="tooltip">
                                      Games that are not yet confirmed to be leaving soon, but have been on the service for a while and are likely to leave in the next few months.
                                    </span>
                                  </button>
                                </div>
                              </label>
                            </div>

                            <div class="flex items-center mt-1">
                              <input 
                              v-model="typeOther" 
                              @change="uncheckTypeAll('typeOther')" 
                              type="checkbox" 
                              class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                              id="hs-checkbox-group-18">
                              <label for="hs-checkbox-group-18" class="text-sm text-gray-400 ml-3">Other</label>
                            </div>
                          </div>
            
                          <p class="mt-1 text-center text-white">
                            Preferred Game Properties
                          </p>
                          
                          <div class="flex justify-center items-center mt-2">
                            <!-- First Label -->
                            <label
                              :class="{'text-white': !propertiesSwitchIsChecked, 'text-gray-500': propertiesSwitchIsChecked}"
                              class="mr-3 text-sm transition-colors duration-300"
                            >
                              All Properties Except...
                            </label>

                            <!-- Toggle Switch -->
                            <div @click.prevent="propertiesSwitchIsChecked = !propertiesSwitchIsChecked" class="relative">
                              <input
                                type="checkbox"
                                id="toggleSwitch"
                                class="sr-only peer w-full h-full cursor-pointer"
                                v-model="propertiesSwitchIsChecked"
                              />
                              <div
                                class="block bg-red-500 peer-checked:bg-green-400 w-7 h-4 rounded-full transition-colors duration-300"
                              ></div>
                              <div
                                class="dot absolute left-0.5 top-0.5 bg-white w-3 h-3 rounded-full transition-transform duration-300 ease-in-out peer-checked:transform peer-checked:translate-x-3"
                              ></div>
                            </div>

                            <!-- Second Label -->
                            <label
                              :class="{'text-white': propertiesSwitchIsChecked, 'text-gray-500': !propertiesSwitchIsChecked}"
                              class="ml-3 text-sm transition-colors duration-300"
                            >
                              All Properties Like...
                            </label>
                          </div>

                          <div class="flex justify-center py-4">
                            <div class="max-h-32 overflow-y-auto border text-sm text-center rounded-lg block p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-green-500 focus:border-green-500 scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
                              <div 
                                v-for="(property, index) in allPossibleProperties" 
                                :key="index" 
                                :value="property" 
                                @click="toggleProperty(property)"
                                :class="{
                                  'bg-green-500': propertiesSelected.includes(property) && propertiesSwitchIsChecked,
                                  'bg-red-500': propertiesSelected.includes(property) && !propertiesSwitchIsChecked,
                                }"
                                class="cursor-pointer"
                              >
                                {{ property }}
                              </div>
                            </div>
                          </div>
                          
                        </div>
                        <div class="flex justify-center items-center gap-x-2 py-3 px-4 border-t border-gray-700">
                          <button @click="applyPickingPreferences" class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-primary text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm focus:ring-offset-gray-800" href="#" data-hs-overlay="#hs-basic-modal">
                            Save changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- POP-UP MODAL TO ESTABLISH PICKING SETTINGS (ENDS) -->
                  <!-- POP-UP MODAL FOR GAME PROPERTIES (STARTS) -->
                  <div id="hs-gameprops-modal" class="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto flex justify-center items-center inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm">
                    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all sm:max-w-lg sm:w-full m-3">
                      <div class="flex flex-col border shadow-sm rounded-xl bg-gray-800 border-gray-700 shadow-slate-700/[.7]">
                        <div class="flex justify-between items-center py-3 px-4 border-b border-gray-700">
                          <h3 class="font-bold text-white">
                            Game Properties
                          </h3>
                          <button type="button" class="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm focus:ring-gray-700 focus:ring-offset-gray-800" data-hs-overlay="#hs-gameprops-modal">
                            <span class="sr-only">Close</span>
                            <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                            </svg>
                          </button>
                        </div>
                        <div class="p-4 overflow-y-auto">
                          <div v-if="this.gameChosen && this.gameChosen['gameAttributes']"  class="p-4 overflow-y-auto">
                            <div class="mt-1 text-gray-400" v-for="(attribute, index) in this.gameChosen['gameAttributes']" :key="index">
                              {{ attribute }}
                            </div>
                          </div>
                          <div v-else>
                            <div class="mt-1 text-gray-400">
                              No game properties available.
                            </div>
                          </div>
                        </div>
                        <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t border-gray-700">
                          <button type="button" class="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium shadow-sm align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 transition-all text-sm bg-slate-900 hover:bg-slate-800 border-gray-700 text-gray-400 hover:text-white focus:ring-offset-gray-800" data-hs-overlay="#hs-gameprops-modal">
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- POP-UP MODAL FOR GAME PROPERTIES (ENDS) -->

                </div>
                <div class="flex justify-between items-start my-4" v-if="isLargeScreen">
                  <div class="flex flex-col space-y-2">
                    <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('wishlist')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 text-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      <font-awesome-icon class="text-white px-1" icon="fa-solid fa-gift" />&nbsp;&nbsp;Interested
                    </button>
                    <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 text-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      <font-awesome-icon class="text-white px-1" icon="fa-solid fa-gift" />&nbsp;&nbsp;Interested
                    </button>
                    <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('uninterested')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      <font-awesome-icon class="text-white px-1" icon="fa-solid fa-trash" />Uninterested&nbsp;
                    </button>
                    <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      <font-awesome-icon class="text-white px-1" icon="fa-solid fa-trash" />Uninterested&nbsp;
                    </button>
                  </div>

                  <div class="flex flex-col space-y-2 items-center">
                    <button v-if="!suggestGameClickedForTheFirstTime" type="button" @click="pickRandomGamePassGame" :disabled="choosingGameInProcess" class="px-4 inline-flex justify-center items-center rounded-md border border-transparent font-semibold bg-primary text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm p-1 sm:p-3 ring-offset-gray-800">
                      {{ !choosingGameInProcess ? 'Suggest Game' : 'Suggesting.....' }}
                    </button>
                    <button v-if="suggestGameClickedForTheFirstTime" type="button" @click="pickRandomGamePassGame" :disabled="choosingGameInProcess" class="px-4 inline-flex justify-center items-center rounded-md border border-transparent font-semibold bg-primary text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm p-1 sm:p-3 ring-offset-gray-800">
                      {{ !choosingGameInProcess ? 'Suggest Another' : 'Suggesting.........' }}
                    </button>
                    <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('currently playing')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      <font-awesome-icon class="text-white px-1" icon="fa-solid fa-gamepad" />Playing&nbsp;
                    </button>
                    <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      <font-awesome-icon class="text-white px-1" icon="fa-solid fa-gamepad" />Playing&nbsp;
                    </button>
                  </div>

                  <div class="flex flex-col space-y-2">
                    <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('played')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 text-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      Played&nbsp;&nbsp;<font-awesome-icon class="text-white px-1" icon="fa-solid fa-hourglass-half" />
                    </button>
                    <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 text-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      Played&nbsp;&nbsp;<font-awesome-icon class="text-white px-1" icon="fa-solid fa-hourglass-half" />
                    </button>
                    <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('completed')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 text-right gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      Completed&nbsp;&nbsp;<font-awesome-icon class="text-white px-1" icon="fa-solid fa-check-to-slot" />
                    </button>
                    <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 text-right gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      Completed&nbsp;&nbsp;<font-awesome-icon class="text-white px-1" icon="fa-solid fa-check-to-slot" />
                    </button>
                  </div>
                </div>
                <div class="my-2" v-else>


                  <div class="flex flex-row justify-center mb-2">
                    <div class="flex flex-col">
                      <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('wishlist')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Interested
                      </button>
                      <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Interested
                      </button>
                    </div>
                    <button v-if="!suggestGameClickedForTheFirstTime" type="button" @click="pickRandomGamePassGame" :disabled="choosingGameInProcess" class="mx-1 px-2 p-1 sm:p-4 py-2 inline-flex justify-center items-center rounded-md border border-transparent font-semibold bg-primary text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs ring-offset-gray-800">
                      {{ !choosingGameInProcess ? 'Suggest Game' : 'Suggest Game' }}
                    </button>
                    <button v-if="suggestGameClickedForTheFirstTime" type="button" @click="pickRandomGamePassGame" :disabled="choosingGameInProcess" class="mx-1 px-2 p-1 sm:p-4 py-2 inline-flex justify-center items-center rounded-md border border-transparent font-semibold bg-primary text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs ring-offset-gray-800">
                      {{ !choosingGameInProcess ? 'Suggest Another' : 'Suggest Another' }}
                    </button>
                    <div class="flex flex-col">
                      <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('uninterested')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-2 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Uninterested
                      </button>
                      <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-2 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Uninterested
                      </button>
                    </div>
                  </div>

                  <div class="flex flex-row justify-center">
                    <div class="flex flex-col">
                      <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('currently playing')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Playing
                      </button>
                      <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Playing
                      </button>
                    </div>
                    <div class="flex flex-col">
                      <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('played')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-2 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Played
                      </button>
                      <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-2 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Played
                      </button>
                    </div>
                    <div class="flex flex-col">
                      <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('completed')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-2 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Completed
                      </button>
                      <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-2 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Completed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-white text-xl text-left" v-if="aboutPageVisible">
            <p>Hey there 👋 - I created the game pass game picker because
            I love playing games but increasingly found myself in 
            analysis paralysis with so many options on the Xbox Game
            Pass. With the game picker, I often just let chance 
            dictate what I play so that I spend more time playing
            and less time deciding. My strategy is to try a new game every
            few days, and if I find one that I love I will keep
            playing it. I hope it helps someone else out there!
            </p>
            <p class="pt-2 text-sm">
              ~ Made with ❤️ by <a href="https://www.mihaisplace.com" 
              target="_blank"
              rel="noreferrer">
                Mihai Avram
              </a>
            </p>
            <div class="mt-10 text-bold">Attributions
              <div class="text-xs">
                - Thanks to <a href="https://brandlogos.net/xbox-logo-svg-92837.html">brandlogos</a> for the Xbox Logo
              </div>
            </div>
          </div>
        </div>
      </main>
      <!-- ========== END MAIN CONTENT ========== -->

      <!-- NOTIFICATION (STARTS) -->
      <div v-if="notificationPresent" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-xs border rounded-xl shadow-lg bg-gray-800 border-primary-nowhite z-50" role="alert">
        <div class="p-4 relative">
          <button @click="notificationPresent = false" class="absolute top-2 right-2 text-gray-200 hover:text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div class="mb-2 text-center flex justify-center items-center">
            <svg class="flex-shrink-0 h-4 w-4 text-primary mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </div>
          <div class="ms-3">
            <p class="text-lg text-left text-gray-200 pl-2">{{ notification }}</p>
            <div v-if="isPlayingOrPlayedGame && !notificationLinkPresent" class="mt-3">
              <hr />
              <div class="mt-2 text-lg font-bold">What would you rate this game?</div>
              <div class="flex justify-center align-center mt-2">
                <span class="rate">
                  <i @click="rateGame(gameChosen['title'], 1)">★</i>
                  <i @click="rateGame(gameChosen['title'], 2)">★</i>
                  <i @click="rateGame(gameChosen['title'], 3)">★</i>
                  <i @click="rateGame(gameChosen['title'], 4)">★</i>
                  <i @click="rateGame(gameChosen['title'], 5)">★</i>
                </span>
              </div>
            </div>
            <div v-if="notificationLinkPresent" class="text-lg text-center mt-2">
                <router-link to="/buy-me-a-coffee" class="hover:underline">Buy Me A Coffee</router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- NOTIFICATION (ENDS) -->


      <FooterComponent />
    </div>
  </body>


</template>

<script>
import axios from 'axios';

import { useHead } from '@vueuse/head';

import FooterComponent from '../components/Layout/FooterComponent.vue';
import HeaderComponent from '../components/Layout/HeaderComponent.vue';

// Axios configurations
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;


useHead({
  title: 'Xbox GamePass Picker - Discover the Best GamePass Games!',
  meta: [
    { 
      name: 'description', 
      content: 'Try the GamePass Picker to discover the best GamePass games, review games, and use our game finder to get personalized game recommendations. With features like a game randomizer, updates on games leaving GamePass, random GamePass game selection, and game avatars, GamePass Picker offers everything you need for an enhanced gaming experience. Join the game community to find gaming friends, join gaming competitions, and enjoy gaming with friends across GamePass Core, GamePass PC, and GamePass Ultimate games.' 
    },
    { 
      name: 'keywords', 
      content: 'best GamePass games, review games, game finder, game recommendations, game randomizer, games leaving GamePass, random GamePass game, game avatars, game suggestions, random game selector, find gaming friends, game community, gaming competitions, gaming with friends, GamePass new games, GamePass Core games, GamePass PC games, GamePass Ultimate games' 
    },
    { 
      property: 'og:title', 
      content: 'Try the GamePass Picker - Unlock Top GamePass Features and Community' 
    },
    { 
      property: 'og:description', 
      content: 'Discover and try the GamePass Picker to access the best GamePass games, game reviews, a game finder, personalized game recommendations, and a thriving game community. Join now to explore top features, play with friends, and enjoy GamePass Core, PC, and Ultimate games!' 
    },
  ],
});

export default {
  name: 'GamePickerPage',
  data: function() {
    return {
      addGameInterestSelected: false,
      pageCreatedDone: false,
      pickingPreferenceSelected: false,
      gameChosen: null,
      allGames: [],
      filteredGames: [],
      choosingGameInProcess: false,
      genresSelected: ['All'],
      propertiesSelected: ['All'],
      allPossibleGenres: [],
      allPossibleProperties: [],
      platformAll: true,
      platformXbox: false,
      platformPc: false,
      pickCount: 0,
      typeAll: true,
      typeNewReleases: false,
      typeLeavingSoon: false,
      typePotentiallyLeavingSoon: false,
      typeOther: false,
      ignScoresAll: true,
      ignScoresTopRated: false,
      ignScoresWorthwhile: false,
      ignScoresSubpar: false,
      ignScoresNotRated: false,
      isPlayingOrPlayedGame: false,
      gameplayLenAll: true,
      gameplayLenZeroToFiveHours: false,
      gameplayLenFiveToTwentyHours: false,
      gameplayLenTwentyToFiftyHours: false,
      gameplayLenOverFiftyHours: false,
      numGamesToChooseFrom: 0,
      fewGamesToChooseFrom: false,
      gamePickedCounter: 0,
      gamePickedCounterThreshold: 1000000,
      currentGameImageShown: 0,
      authenticated: false,
      user_id: null,
      userQuota: null,
      notificationPresent: false,
      notification: 'Game has been added to your list successfully',
      notificationLinkPresent: false,
      notificationTimeoutId: null,
      suggestGameClickedForTheFirstTime: false,
      isLargeScreen: false,
      genreSwitchIsChecked: true,
      propertiesSwitchIsChecked: true,
    }
  },
  components: {
    HeaderComponent,
    FooterComponent,
  },
  created: async function() {

    // If this is the first land on the gamepicker and user is authenticated
    // then we send them directly to the news page
    if (window.history.length <= 2) {
      if (await this.getUserLoginStatus()) {
        this.$router.push('/news');
      }
    }

    this.pageCreatedDone = true;

    // Otherwise we keep them in the random game picker page
    await this.initializeData();
  },
  computed: {
    metacriticUrl() {
      return `https://www.metacritic.com/search/${this.gameChosen['searchableTitle']}/`;
    },
    howLongToBeatUrl() {
      return `https://howlongtobeat.com/?q=${this.gameChosen['searchableTitle']}`;
    },
  },
  watch: {
    genreSwitchIsChecked() {
      this.genresSelected = ['All'];
    },
    propertiesSwitchIsChecked() {
      this.propertiesSelected = ['All'];
    },
  },
  methods: {
    initializeData: async function() {
      // First we check the screen size
      await this.checkScreenSize();

      // Setting up all the games from our dataset
      const allGames = await require('../assets/data/games-info-all.json');
      const allGamesData = allGames['data'];
      const allGamesPresentOnGamepass = allGamesData.filter(
        game => game['left_gamepass'] === false
      );

      this.allGames = allGamesPresentOnGamepass;
      this.filteredGames = allGamesPresentOnGamepass;
      
      // Randomizing games so the randomized picking order is achieved with no duplicates
      await this.pseudoRandomizeGames();

      // Extracting our unique genres from our dataset
      const allGenres = this.allGames.reduce(
        (acc, game) => [...acc, ...game['ign_all_possible_genres']], []
      );
      const uniqueGenres = [...new Set(allGenres)];

      const initialGenresToPickOrder = [
        "All",
        "Shooter",
        "Action",
        "Adventure",
        "RPG",
        "Simulation",
        "Battle Royale",
        "Sports",
        "Racing",
        "Strategy",
        "Puzzle",
        "Fighting"
      ]

      const restOfGenres = uniqueGenres.filter(genre => !initialGenresToPickOrder.includes(genre));

      // Sort the restOfGenres in alphabetical order
      restOfGenres.sort((a, b) => a.localeCompare(b));

      const properlyOrderedGenresList = initialGenresToPickOrder.concat(restOfGenres);

      this.allPossibleGenres = properlyOrderedGenresList;

      // Extracting our unique game properties from our dataset
      const allGameProperties = this.allGames.reduce(
        (acc, game) => [...acc, ...game['game_attributes']], []
      );
      const uniqueGameProperties = [...new Set(allGameProperties)];

      const initialGamePropertiesToPickOrder = [
        "All",
        "Cloud Gaming Enabled",
        "Single Player",
        "Online Multiplayer (Xbox Live)",
        "Local Multiplayer (Xbox Live)",
        "Local Multiplayer",
        "Online Co-op Support",
        "Online Co-op (Xbox Live)",
        "Local Co-op (Xbox Live)",
        "Local Co-op Support",
        "Shared Split-Screen",
        "Cross-Platform Multiplayer (Xbox Live)",
        "Cross-Platform Co-op (Xbox Live)",
        "Optimized for Xbox Series X/S",
        "4K Resolution Support",
        "Cross-Generation Play (Console)",
        "Cross-Generation Multiplayer (Xbox Live)",
        "Online Multiplayer (Gold Required)",
        "Xbox Live Enabled",
        "Achievements (Xbox Live)",
        "Game Streaming Support",
        "Broadcasting Support",
        "Keyboard and Mouse Support (Console)",
        "Gamepad Support (PC)",
        "120 FPS Support",
        "60 FPS Support",
        "Ray Tracing Support",
        "HDR Support",
        "VRR Support (Variable Refresh Rate)",
        "Xbox Enhanced Features",
        "DTS:X Audio Support",
        "Dolby Atmos Support",
        "Spatial Sound Support",
        "Xbox Play Anywhere",
        "Cloud Saves (Xbox Live)",
        "Xbox Live Clubs",
        "Xbox Live Presence"
      ];

      const restOfGameProperties = uniqueGameProperties.filter(gameProperty => !initialGamePropertiesToPickOrder.includes(gameProperty));

      // Sort restOfGameProperties in alphabetical order
      restOfGameProperties.sort((a, b) => a.localeCompare(b));

      const properlyOrderedGamePropertiesList = initialGamePropertiesToPickOrder.concat(restOfGameProperties);

      this.allPossibleProperties = properlyOrderedGamePropertiesList;

      // Attempting to get user information in case they are logged in
      await this.getUserLoginStatus();

      // We next get the user's leftover quota
      await this.getUserSubscriptionQuota();
    },
    generateGameDetailSlug: function(gameTitle) {
      return gameTitle
        .toLowerCase()                       // Convert to lowercase
        .replace(/[^a-z0-9\s-]/g, '')        // Remove invalid characters
        .trim()                              // Remove leading/trailing spaces
        .replace(/\s+/g, '-')                // Replace spaces with hyphens
        .replace(/-+/g, '-')                 // Remove duplicate hyphens
    },
    viewGameDetailsPage: async function(gameId, gameTitle) {

      const gameDetailSlug = await this.generateGameDetailSlug(gameTitle);
      this.$router.push(
        { 
          name: 'GameDetailsPage', 
          params: { gameId: gameId, gameTitle: gameDetailSlug} 
        }
      );
    },
    getUserSubscriptionQuota: async function() {
      // Getting the user's subscription quota only applies
      // if the user is authenticated
      if (!this.authenticated) {
        return;
      }

      // Make the API call
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_API_ROUTE}/get-user-subscription-quota/`,
          {
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': csrfToken
            },
            withCredentials: true,
          })

        if (response.data) {
          this.userQuota = response.data;
        }

      } catch (error) {
        console.error('Could not retrieve the subscription quota for the user.', error);
      }
    },
    getUserLoginStatus: async function () {
      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/user`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'  // Necessary for passing of login cookies from backend
        })

        const content = await response.json();

        if (content.detail === 'Unauthenticated!' || content.detail === 'Authentication credentials were not provided.') {
          this.authenticated = false;
        } else {
          this.authenticated = true;
          this.user_id = content.id;
        }
      } catch (e) {
        this.authenticated = false;
      }

      return this.authenticated;
    },
    nextGameImageShown() {
      if (this.currentGameImageShown < this.gameChosen['images'].length - 1) {
        this.currentGameImageShown++;
      } else {
        this.currentGameImageShown = 0;
      }
    },
    prevGameImageShown() {
      if (this.currentGameImageShown > 0) {
        this.currentGameImageShown--;
      } else {
        this.currentGameImageShown = this.gameChosen['images'].length - 1;
      }
    },
    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    },
    uncheckGameplayLenAll(gameplayLen) {
      if (this[gameplayLen]) {
        this.gameplayLenAll = false;
      }
    },
    uncheckPlatformAll(platform) {
      if (this[platform]) {
        this.platformAll = false;
      }
    },
    uncheckIgnScoresAll() {
      this.ignScoresAll = false;
    },
    uncheckTypeAll(type) {
      if (this[type]) {
        this.typeAll = false;
      }
    },
    toggleGenre(genre) {
      // If "All" is selected, deselect everything else
      if (genre === 'All') {
        this.genresSelected = this.genresSelected.includes('All') ? [] : ['All'];
      } else {
        // If anything else is selected, deselect "All"
        if (this.genresSelected.includes('All')) {
          this.genresSelected = [];
        }
        if (this.genresSelected.includes(genre)) {
          this.genresSelected = this.genresSelected.filter(g => g !== genre);
        } else {
          this.genresSelected.push(genre);
        }
      }
    },
    toggleProperty(property) {
      // If "All" is selected, deselect everything else
      if (property === 'All') {
        this.propertiesSelected = this.propertiesSelected.includes('All') ? [] : ['All'];
      } else {
        // If anything else is selected, deselect "All"
        if (this.propertiesSelected.includes('All')) {
          this.propertiesSelected = [];
        }
        if (this.propertiesSelected.includes(property)) {
          this.propertiesSelected = this.propertiesSelected.filter(g => g !== property);
        } else {
          this.propertiesSelected.push(property);
        }
      }
    },
    isRecentlyReleased: function (game) {
      if (!game.releaseOnGamePassDate) return false;
      const releaseDate = new Date(game.releaseOnGamePassDate);
      const today = new Date();
      const timeDifference = today - releaseDate;
      const daysDifference = timeDifference / (1000 * 3600 * 24);
      return daysDifference <= 7;
    },
    pseudoRandomizeGames: function () {
      // Randomize the filtered games so that the picking order is different
      // each time we apply the picking preferences
      if (this.filteredGames.length > 0) {
        this.filteredGames = this.filteredGames.sort(() => Math.random() - 0.5);
      }
      this.gamePickedCounterThreshold = this.filteredGames.length - 1;
      
      // Resetting the game picked counter to 0 as we start fresh w/ new search
      this.gamePickedCounter = 0;
    },
    makeGameNameSearchable: function (gameName) {
      if (!gameName) {
        return '';
      }

      const elements = [
          "™",
          "®",
          "(Game Preview)",
          "(Xbox Series X|S & PC)",
          "(Xbox Series X|S)",
          "(Xbox One Edition)",
          "(Xbox One)",
          "Game Preview",
          "Xbox Series X|S & PC",
          "Xbox Series X|S",
          "Xbox One Edition",
          "Xbox One"
      ];

      let result = gameName;
      for (let element of elements) {
          const regex = new RegExp(this.escapeRegExp(element), 'g');
          result = result.replace(regex, "");
      }
      return result.trim();
    },
    navigateToAboutPage: function() {
      this.$router.push('/about');
    },
    navigateToSignUpPage: function() {
      this.$router.push('/signup');
    },
    navigateToLoginPage: function() {
      this.$router.push('/login');
    },
    navigateToGamePickerPage: function() {
      this.$router.push('/');
    },
    checkScreenSize() {
      this.isLargeScreen = window.innerWidth > 768; // Example breakpoint
    },
    clearExistingNotification() {
      if (this.notificationTimeoutId) {
        clearTimeout(this.notificationTimeoutId);
        this.notificationTimeoutId = null;
        this.notificationPresent = false;
        this.notificationLinkPresent = false;
        this.isPlayingOrPlayedGame = false;
      }
    },
    addGameInterest: function(interest_status) {
      // Clearing any existing notification
      this.clearExistingNotification();

      if (!this.authenticated) {
        console.log('You must be logged in to add a game to your list of interests.');
        return;
      }

      // Checking if the user is adding a playing, played, or completed game
      // This is to see if the user needs to rate the game or not
      if (
        interest_status === 'currently playing' || 
        interest_status === 'played'|| 
        interest_status === 'completed') {
        this.isPlayingOrPlayedGame = true;
      }

      // For notifications where the user needs to rate the game, we increase
      // the timeout
      const timeoutDurationSeconds = this.isPlayingOrPlayedGame ? 6 : 2;

      // Define the payload data
      const inputData = {
        game_title: this.gameChosen['title'],
        status: interest_status
      };

      // Make the API call
      axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/game-interest/`, inputData,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        })
        .then(response => {
          // Handle success, maybe provide feedback to the user or update the UI
          console.log('Successfully changed game interest', response.data);

          if (interest_status === 'uninterested') {
            this.notification = 'Game has been removed from your preferences and lists successfully';
          } else if (interest_status === 'wishlist') {
            this.notification = 'Game has been added to your "Interested" list successfully';
          } else if (interest_status === 'currently playing') {
            this.notification = 'Game has been added to your "Currently Playing" list successfully';
          } else if (interest_status === 'played') {
            this.notification = 'Game has been added to your "Played" list successfully';
          } else if (interest_status === 'completed') {
            this.notification = 'Game has been added to your "Completed" list successfully';
          }

          // Notifying user that the game has been added to their list
          this.notificationPresent = true;

          this.notificationTimeoutId = setTimeout(() => {
              this.notificationPresent = false;
              this.isPlayingOrPlayedGame = false;
          }, timeoutDurationSeconds * 1000);

          // Depleting the user's quota
          this.depleteQuota('game_addition_quota')
          
          this.pickingPreferenceSelected = false;
          this.addGameInterestSelected = true;
        })
        .catch(error => {
          // Handle error, maybe show an error message to the user
          console.error('There was an error!', error);
        });
    },
    depleteQuota: async function (attemptType) {
      // Depleting the user's quota only applies
      // if the user is authenticated
      if (!this.authenticated) {
        return;
      }

      const inputData = {
        attempt_type: attemptType
      };

      // Make the API call
      try {
        await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/quota-deplete/`, inputData,
          {
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': csrfToken
            },
            withCredentials: true,
          })

        // We also deplete the quota in the front-end
        if (attemptType === 'game_addition_quota') {
          this.userQuota['game_addition_quota'] -= 1;
        } else if (attemptType === 'gp_picking_preferences_quota') {
          this.userQuota['gp_picking_preferences_quota'] -= 1;
        }
      } catch (error) {
        console.error('Could not deplete the quota for the user.', error);
      }
    },
    pickRandomGamePassGame: async function() {

      if (!this.suggestGameClickedForTheFirstTime) {
        this.suggestGameClickedForTheFirstTime = true;
      }

      this.pickCount += 1;

      // Clearing any existing notification
      this.clearExistingNotification();

      // Resetting the image index to be 0, so we always see the first photo 
      // for the game
      this.currentGameImageShown = 0;

      if (this.filteredGames.length === 0) {

        this.notification = 'There are no games to pick from! Your picking preferences are too restrictive, please change them and try again.';
        this.notificationPresent = true;

        this.notificationTimeoutId = setTimeout(() => {
            this.notificationPresent = false;
        }, 3000);

      } else {

        // We have some games to pick from so we proceed
        this.choosingGameInProcess = true;

        // Sleeping for half a second to allow the browser to update
        await new Promise(resolve => setTimeout(resolve, 500));

        try {


          // TODO: Eventually we want to move this to a GraphQL Database and have
          // the backend hook-into that and we serve this via request to backend

          const gamePicked = this.filteredGames[this.gamePickedCounter];
          // Incrementing by one so that next time user hits pick game, we pick the next game
          this.gamePickedCounter += 1;

          // If we reach the threshold, we reset the counter
          if (this.gamePickedCounter > this.gamePickedCounterThreshold) {
            this.gamePickedCounter = 0;
          }

          const backupMicrosoftStoreUrl = `https://www.xbox.com/en-us/games/store/${gamePicked['title']}/${gamePicked['xbox_api_id']}`
          const backupYoutubeReviewUrl = `https://www.youtube.com/results?search_query=${gamePicked['title']}+review`;
          const backupYoutubeTrailerUrl = `https://www.youtube.com/results?search_query=${gamePicked['title']}+trailer`;
          const backupYoutubeWalkthroughUrl = `https://www.youtube.com/results?search_query=${gamePicked['title']}+full+walkthrough`;

          this.gameChosen = {
            'title': gamePicked['title'] ? gamePicked['title'] : 'NA',
            'searchableTitle': this.makeGameNameSearchable(gamePicked['title']),
            'description': gamePicked['short_description'] ? gamePicked['short_description'] : 'Description not available.',
            'images': gamePicked['image_uris'] ? gamePicked['image_uris'] : 'NA',
            'developerName': gamePicked['developer_name'] ? gamePicked['developer_name'] : 'NA',
            'platforms': gamePicked['platforms'] ? gamePicked['platforms'] : 'NA',
            'ignScore': gamePicked['ign_score'] ? gamePicked['ign_score'] : 'NA',
            'ignDurationHours': gamePicked['ign_duration_hours'] ? gamePicked['ign_duration_hours'] : 'NA',
            'ignAllPossibleGenres': gamePicked['ign_all_possible_genres'] ? gamePicked['ign_all_possible_genres'] : 'NA',
            'ignUrl': gamePicked['ign_url'] ? gamePicked['ign_url'] : 'https://www.ign.com/',
            'microsoftStoreUrl': gamePicked['microsoft_store_url'] ? gamePicked['microsoft_store_url'] : backupMicrosoftStoreUrl,
            'leavingSoon': gamePicked['leaving_soon'],
            'leavingSoonDate': gamePicked['leaving_soon_date'] ? gamePicked['leaving_soon_date'] : 'Soon',
            'originalReleaseYear': gamePicked['original_release_year'] ? gamePicked['original_release_year'] : '--',
            'releaseOnGamePassDate': gamePicked['release_on_gamepass_date'] ? gamePicked['release_on_gamepass_date'] : null,
            'youtubeReviewUrl': gamePicked['youtube_review_url'] ? gamePicked['youtube_review_url'] : backupYoutubeReviewUrl,
            'youtubeTrailerUrl': gamePicked['youtube_trailer_url'] ? gamePicked['youtube_trailer_url'] : backupYoutubeTrailerUrl,
            'youtubeWalkthroughUrl': gamePicked['youtube_walkthrough_url'] ? gamePicked['youtube_walkthrough_url'] : backupYoutubeWalkthroughUrl,
            'gameAttributes': gamePicked['game_attributes'],
            'xboxApiId': gamePicked['xbox_api_id'] ? gamePicked['xbox_api_id'] : 'NA'
          }

          // One in 50 times (so 2% of the time), we show a support me msg
          if (Math.floor(Math.random() * 50) === 0) {
            this.notification = 'If you love the game picker, please consider supporting me in a small way!';
            this.notificationPresent = true;

            this.notificationLinkPresent = true;

            this.notificationTimeoutId = setTimeout(() => {
                this.notificationPresent = false;
                this.notificationLinkPresent = false;
            }, 6000);
          }

        } catch (error) {
          console.log('Oops, an error has ocurred when picking this game');
          console.log(error);

          this.choosingGameInProcess = false;
        }

          this.choosingGameInProcess = false;
      }

      // We record the first time the user picks a game and deplete the user's 
      // quota for telemetry
      if (this.pickCount === 1) {
        await this.depleteQuota('random_game_picks_quota');
      }
    },
    applyPickingPreferences: async function() {
      // Clearing any existing notification
      this.clearExistingNotification();

      // Making a deep copy of all the games first
      this.filteredGames = JSON.parse(JSON.stringify(this.allGames));

      // Attempting to filter based on genre
      if (this.genreSwitchIsChecked) {
        // We are doing an all genres inclusive search
        if (this.genresSelected.includes('All')) {
          // We pass, as we already have all the games available
        } else {
          // Otherwise, we filter to include the genres selected
          this.filteredGames = this.filteredGames.filter(game => 
            this.genresSelected.some(genre => game.ign_all_possible_genres.includes(genre))
          );
        }
      } else {
        // We are doing an all genres except search
        if (this.genresSelected.includes('All')) {
          // We are excluding all genres
          this.filteredGames = [];
        } else {
          // Otherwise, we filter to exclude the genres selected
          this.filteredGames = this.filteredGames.filter(game => 
            game.ign_all_possible_genres.every(genre => !this.genresSelected.includes(genre))
          );
        }
      }

      // Attempting to filter based on Platform
      if (!this.platformAll) {
        if (this.platformXbox && this.platformPc) {
          this.filteredGames = this.filteredGames.filter(game => game.platforms.includes('Xbox') || game.platforms.includes('PC'));
        } else if (this.platformXbox) {
          this.filteredGames = this.filteredGames.filter(game => game.platforms.includes('Xbox'));
        } else if (this.platformPc) {
          this.filteredGames = this.filteredGames.filter(game => game.platforms.includes('PC'));
        }
      }

      // Attempting to filter based on Gameplay Length
      if (this.gameplayLenAll) {
        // We pass, as we already have all the games available
      } else {
        // We first have to convert the ign duration from text to hours
        this.filteredGames = this.filteredGames.map(game => {
          let gameplayHours = game.ign_duration_hours;
          if (gameplayHours) {
            gameplayHours = gameplayHours.replace(" hrs", "");
          }

          game.ign_duration_hours = parseInt(gameplayHours);
          return game;
        });

        // Filtering games
        // Initialize an array to hold the filtered games for each condition
        let filtered = [];

        // Check each condition independently
        if (this.gameplayLenZeroToFiveHours) {
          filtered = [...filtered, ...this.filteredGames.filter(game => game.ign_duration_hours >= 0 && game.ign_duration_hours < 5)];
        }
        if (this.gameplayLenFiveToTwentyHours) {
          filtered = [...filtered, ...this.filteredGames.filter(game => game.ign_duration_hours >= 5 && game.ign_duration_hours < 20)];
        }
        if (this.gameplayLenTwentyToFiftyHours) {
          filtered = [...filtered, ...this.filteredGames.filter(game => game.ign_duration_hours >= 20 && game.ign_duration_hours < 50)];
        }
        if (this.gameplayLenOverFiftyHours) {
          filtered = [...filtered, ...this.filteredGames.filter(game => game.ign_duration_hours >= 50)];
        }

        // Remove duplicates from the filtered array and assign it back to this.filteredGames
        this.filteredGames = [...new Set(filtered)];

        // Converting the parsed ign duration from integer hours back to text
        this.filteredGames = this.filteredGames.map(game => {
          if (game.ign_duration_hours) {
            game.ign_duration_hours = `${game.ign_duration_hours} hrs`;
          }
          return game;
        });
      }

      // Filter based on IGN Rating
      if (this.ignScoresAll) {
        // We pass, as we already have all the games available
      } else {
        // Attempting to filter based on IGN Scores
        // Initialize an array to hold the filtered games for each condition
        let filtered = [];

        // Check each condition independently
        if (this.ignScoresTopRated) {
          // Filter based on ign_score converted to a float and between 8 and 10
          filtered = [
            ...filtered,
            ...this.filteredGames.filter(game => {
              const score = parseFloat(game.ign_score);
              return !isNaN(score) && score >= 8 && score <= 10;
            })
          ];
        }

        if (this.ignScoresWorthwhile) {
          // Filter based on ign_score converted to a float and between 5 and 8
          filtered = [
            ...filtered,
            ...this.filteredGames.filter(game => {
              const score = parseFloat(game.ign_score);
              return !isNaN(score) && score >= 5 && score < 8;
            })
          ];
        }

        if (this.ignScoresSubpar) {
          // Filter based on ign_score converted to a float and between 1 and 4
          filtered = [
            ...filtered,
            ...this.filteredGames.filter(game => {
              const score = parseFloat(game.ign_score);
              return !isNaN(score) && score >= 0 && score < 5;
            })
          ];
        }

        if (this.ignScoresNotRated) {
          // Filter based on ign_score which is "NR"
          filtered = [
            ...filtered,
            ...this.filteredGames.filter(game => game.ign_score === "NR" || game.ign_score === null)
          ];
        }

        // Remove duplicates from the filtered array and assign it back to this.filteredGames
        this.filteredGames = [...new Set(filtered)];
      }


      if (this.typeAll) {
        // We pass, as we already have all the games available
      } else {
        // Attempting to filter based on Game Type (e.g. New Releases/Leaving Soon)
        // Initialize an array to hold the filtered games for each condition
        let filtered = [];

        // Check each condition independently
        if (this.typeNewReleases) {
          filtered = [...filtered, ...this.filteredGames.filter(game => game.recently_added)];
        }
        if (this.typeLeavingSoon) {
          filtered = [...filtered, ...this.filteredGames.filter(game => game.leaving_soon)];
        }
        if (this.typePotentiallyLeavingSoon) {
          filtered = [...filtered, ...this.filteredGames.filter(game => game.potentially_leaving_soon)];
        }
        if (this.typeOther) {
          filtered = [...filtered, ...this.filteredGames.filter(game => !game.recently_added && !game.leaving_soon)];
        }

        // Remove duplicates from the filtered array and assign it back to this.filteredGames
        this.filteredGames = [...new Set(filtered)];
      }

      // Attempting to filter based on game properties
      if (this.propertiesSwitchIsChecked) {
        // We are doing an all properties inclusive search
        if (this.propertiesSelected.includes('All')) {
          // We pass, as we already have all the games available
        } else {
          // Otherwise, we filter based on the properties selected
          this.filteredGames = this.filteredGames.filter(game => 
            this.propertiesSelected.some(property => game.game_attributes.includes(property))
          );
        }
      } else {
        // We are doing an all properties except search
        if (this.propertiesSelected.includes('All')) {
          // We are excluding all properties
          this.filteredGames = [];
        } else {
          // Otherwise, we filter to exclude the properties selected
          this.filteredGames = this.filteredGames.filter(game => 
            game.game_attributes.every(property => !this.propertiesSelected.includes(property))
          );
        }
      }

      this.numGamesToChooseFrom = this.filteredGames.length;

      if (this.numGamesToChooseFrom < 6) {
        this.fewGamesToChooseFrom = true;
      } else {
        this.fewGamesToChooseFrom = false;
      }
      
      this.pseudoRandomizeGames();

      // Depleting the user's quota
      this.depleteQuota('gp_picking_preferences_quota')

      this.addGameInterestSelected = false;
      this.pickingPreferenceSelected = true;
    },
    showTooltip(event) {
      const tooltip = event.currentTarget.querySelector('.hs-tooltip-content');
      tooltip.classList.remove('opacity-0', 'invisible');
      tooltip.classList.add('opacity-100', 'visible');
    },
    hideTooltip(event) {
      const tooltip = event.currentTarget.querySelector('.hs-tooltip-content');
      tooltip.classList.remove('opacity-100', 'visible');
      tooltip.classList.add('opacity-0', 'invisible');
    },
    rateGame: async function(gameTitle, newRating) {
      
      if (newRating < 0 || newRating > 5 || isNaN(newRating)) {
        alert('Please enter a rating between 0 and 5.');
        return;
      }

      // Define the payload data
      const inputData = {
        game_title: gameTitle,
        rating: newRating
      };

      // Make the API call
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/rate-game/`, inputData,
          {
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': csrfToken
            },
            withCredentials: true,
          })

          if (response.data) {
            console.log('NEW DATA AFTER RATING GAMES: ', response.data);
            await this.$store.dispatch('setGameKarmaPoints', response.data.points_balance);
          }
          
      } catch (error) {
        console.error('There was an error!', error);
      }

      // Wait for a brief moment (half a second) to show the user that their 
      // rating was successful
      await new Promise(r => setTimeout(r, 250));

      // We clear any present notifications, with a brief API call delay, to
      // show user on mobile screens that their rating was successful
      this.clearExistingNotification();

      // Deplete the user's quota
      await this.depleteQuota('game_ratings_quota');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .custom-checkbox {
    -webkit-appearance: none; /* Removes default checkbox appearance in WebKit-based browsers */
    appearance: none; /* Removes default checkbox appearance */
    width: 1.25rem; /* Add custom width */
    height: 1.25rem; /* Add custom height */
    background-color: #1F2937; /* Tailwind's bg-gray-800 color */
    border: 1px solid #4B5563; /* Tailwind's border-gray-700 color */
    border-radius: 0.25rem; /* Tailwind's rounded class */

    /* Apply custom styles when the checkbox is checked */
    display: inline-block;
    position: relative;
  }

  .custom-checkbox:checked {
    background-color: #42b983;
    border-color: #42b983;
  }

  .custom-checkbox:checked::before {
    content: '✔'; /* Add a checkmark */
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white; /* Checkmark color */
    font-size: 1rem; /* Adjust size as needed */
    text-align: center;
    line-height: 1.25rem; /* Center the checkmark */
  }

  .hs-tooltip-content {
  transition: opacity 0.3s ease-in-out;
  }

  .hs-tooltip-content.opacity-0 {
    opacity: 0;
    visibility: hidden;
  }

  .hs-tooltip-content.opacity-100 {
    opacity: 1;
    visibility: visible;
  }

  .ign-score-container {
    position: relative;
    text-align: center;
    color: white;
  }

  .ign-score {
    position: absolute;
    font-weight: bolder;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -45%);
  }

  /* These styles are applied by default.
    They will also override the hover
    style for all stars to the right
    of the currently hovered star. */
  .rate i, .rate i:hover ~ i {
    color: gray;
    text-shadow: none;
    transition: color 200ms,
                text-shadow 200ms;
    /* This will remove the delay when
      moving the cursor left or right
      within the set of stars. */
    transition-delay: 0;
  }

  /* This is the style that will be
    applied to all stars and then
    subsequently removed from the stars
    to the right of the one being
    hovered. */
  .rate:hover i {
    color: #fc0;
    text-shadow: #fc0 0 0 20px;
  }

  /* Make the effect apply one star at a
    time. Limiting the selector to when
    .rate is hovered removes the effect
    without delay when cursor leaves
    the .rate area. */
  .rate:hover i:nth-child(2) {
    transition-delay: 30ms;
  }

  .rate:hover i:nth-child(3) {
    transition-delay: 60ms;
  }

  .rate:hover i:nth-child(4) {
    transition-delay: 90ms;
  }

  .rate:hover i:nth-child(5) {
    transition-delay: 120ms;
  }

  /* Miscellaneous styles. */
  .rate i {
    cursor: pointer;
    font-style: normal;
  }

</style>
