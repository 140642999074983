<template>

  <body class="bg-slate-900 min-h-full">

    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">

      <HeaderComponent />

      <!-- ========== MAIN CONTENT ========== -->
      <main role="main" class="">
        <div class="grid place-items-center px-4 sm:px-6 lg:px-8">
          <div class="max-w-lg w-full">
            <div class="mb-3 text-left">
              <button class="text-left font-bold mr-3 disabled cursor-auto">View</button>
              <button @click="browseToGameDetails(this.gameChosen['id'], this.gameChosen['title'])"
                      type="button" 
                      :class="[
                        'game-list-btn-selector game-list-btn-selector-left py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-l-lg border bg-secondary text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none',
                        sectionShowing === 'Details' ? 'bg-primary' : 'bg-secondary']">
                Details
              </button>
              <button type="button" 
                      :class="[
                        'game-list-btn-selector game-list-btn-selector-middle py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium border border-transparent text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none',
                        sectionShowing === 'Reviews' ? 'bg-primary' : 'bg-secondary']">
                Reviews
              </button>
              <button @click="browseToGameTips(this.gameChosen['id'], this.gameChosen['title'])"
                      type="button" 
                      :class="[
                        'game-list-btn-selector game-list-btn-selector-right py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-r-lg border border-transparent text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none',
                        sectionShowing === 'Tips' ? 'bg-primary' : 'bg-secondary']">
                Tips
              </button>
            </div>
            <div v-if="loading" class="max-w-lg w-full mt-32">
              <div class="animate-spin inline-block size-6 w-10 h-10 border-[3px] border-current border-t-transparent rounded-full text-white" role="status" aria-label="loading">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div v-else class="max-w-lg w-full mt-10">
              <h1 class="text-xl font-bold">Average User Rating</h1>
                <div class="my-2">
                  <span class="rated">
                    <i :class="averageGameRating > 0 ? 'starred' : ''">★</i>
                    <i :class="averageGameRating > 1 ? 'starred' : ''">★</i>
                    <i :class="averageGameRating > 2 ? 'starred' : ''">★</i>
                    <i :class="averageGameRating > 3 ? 'starred' : ''">★</i>
                    <i :class="averageGameRating > 4 ? 'starred' : ''">★</i>
                  </span>
                </div>
                <div v-if="averageGameRating == null" class="italic">No user has yet rated this game, why don't you be the first?</div>
                <div class="mt-8">
                  <button @click="openReviewGameModal(reviewInformation = null)" type="button" class="px-4 inline-flex justify-center items-center rounded-md border border-transparent font-semibold bg-primary text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm p-1 sm:p-3 ring-offset-gray-800">
                      Review Game
                  </button>
                </div>
                <div class="mt-8">
                  <div v-if="allGameReviews.length == 0" class="text-center text-gray-300 my-4">
                    No reviews available yet for this game, why don't you be the first?
                  </div>
                  <div v-else>
                    <div v-for="(review, index) in allGameReviews" :key="index" class="bg-gray-800 rounded-lg py-2 px-3 mb-4">
                      <div class="flex items-center justify-between mb-2">
                        <div class="text-md font-semibold text-white">
                          <font-awesome-icon :icon="['fas', 'user']" />
                          <router-link :to="`/social/profiles/${review.user}`" class="ml-2 hover:underline">
                            <span class="text-white">{{ review.user }}</span>
                          </router-link>
                        </div>
                        <div>
                          <span class="rated">
                            <i :class="review.rating > 0 ? 'starred' : ''">★</i>
                            <i :class="review.rating > 1 ? 'starred' : ''">★</i>
                            <i :class="review.rating > 2 ? 'starred' : ''">★</i>
                            <i :class="review.rating > 3 ? 'starred' : ''">★</i>
                            <i :class="review.rating > 4 ? 'starred' : ''">★</i>
                          </span>
                        </div>
                      </div>
                      <div class="text-gray-300 text-left break-words">{{ review.review_text }}</div>
                      <div class="flex items-center justify-between mt-2">
                        <div>
                          <font-awesome-icon 
                            @click="voteReview(review.id, true)" 
                            :icon="['fas', 'arrow-up']" 
                            :class="['cursor-pointer hover:text-green-500', {'text-green-500': review.user_vote === true}]"
                          />
                          <span class="text-white px-2">{{ review.total_votes }}</span>
                          <font-awesome-icon 
                            @click="voteReview(review.id, false)" 
                            :icon="['fas', 'arrow-down']" 
                            :class="['cursor-pointer hover:text-red-500', {'text-red-500': review.user_vote === false}]"
                          />
                        </div>
                        <div v-if="review.user_self_created_review">
                          <button @click="openReviewGameModal(reviewInformation = {'review_text': review.review_text, 'review_rating': review.rating})" type="button" class="p-2 inline-flex justify-center items-center rounded-md border border-primary font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs ring-offset-gray-800">
                            Edit Review
                          </button>
                        </div>
                        <div class="text-gray-300 text-xs text-right">{{ makeDateFriendly(review.updated_on) }}</div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </main>
      <!-- ========== END MAIN CONTENT ========== -->

      <!-- NOTIFICATION (STARTS) -->
      <div v-if="notificationPresent" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-xs border rounded-xl shadow-lg bg-gray-800 border-primary-nowhite z-50" role="alert">
        <div class="p-4 relative">
          <button @click="notificationPresent = false" class="absolute top-2 right-2 text-gray-200 hover:text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div class="mb-2 text-center flex justify-center items-center">
            <svg class="flex-shrink-0 h-4 w-4 text-primary mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </div>
          <div class="ms-3">
            <p class="text-lg text-left text-gray-200 pl-2">{{ notification }}</p>
            <div v-if="isPlayingOrPlayedGame && !notificationLinkPresent" class="mt-3">
              <hr />
              <div class="mt-2 text-lg font-bold">What would you rate this game?</div>
              <div class="flex justify-center align-center mt-2">
                <span class="rate">
                  <i @click="rateGame(gameChosen['title'], 1)">★</i>
                  <i @click="rateGame(gameChosen['title'], 2)">★</i>
                  <i @click="rateGame(gameChosen['title'], 3)">★</i>
                  <i @click="rateGame(gameChosen['title'], 4)">★</i>
                  <i @click="rateGame(gameChosen['title'], 5)">★</i>
                </span>
              </div>
            </div>
            <div v-if="notificationLinkPresent" class="mt-2 text-lg text-left text-gray-200 pl-2">
              Add your Xbox GamerTag from the <router-link to="/profile" class="text-primary underline">Profile</router-link> page
            </div>
          </div>
        </div>
      </div>
      <!-- NOTIFICATION (ENDS) -->

      <!-- REVIEW GAME (STARTS) -->
      <div v-if="rateGamePresent" class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-xs border rounded-xl shadow-lg bg-gray-800 border-primary-nowhite z-10" role="alert">
        <div class="p-4 relative">
          <button @click="rateGamePresent = false" class="absolute top-2 right-2 text-gray-200 hover:text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div class="text-left">
            <div class="mt-3 font-bold">What do you think of this game?</div>
            <div class="mt-3 max-w-sm space-y-3">
              <textarea v-model="gameReviewText" class="py-3 px-4 block w-full rounded-lg text-sm focus:border-green-500 disabled:opacity-50 disabled:pointer-events-none bg-neutral-900 border-neutral-700 text-neutral-200 placeholder-neutral-400 focus:ring-neutral-600" rows="5" placeholder="Think of a review that would help other gamers as well..."></textarea>
            </div>
            <div class="mt-3">
              <div class="flex justify-center align-center mt-2">
                <span class="rate">
                  <i @click="rateGameNumStarsSelected = 1" :class="rateGameNumStarsSelected > 0 ? 'starred' : ''">★</i>
                  <i @click="rateGameNumStarsSelected = 2" :class="rateGameNumStarsSelected > 1 ? 'starred' : ''">★</i>
                  <i @click="rateGameNumStarsSelected = 3" :class="rateGameNumStarsSelected > 2 ? 'starred' : ''">★</i>
                  <i @click="rateGameNumStarsSelected = 4" :class="rateGameNumStarsSelected > 3 ? 'starred' : ''">★</i>
                  <i @click="rateGameNumStarsSelected = 5" :class="rateGameNumStarsSelected > 4 ? 'starred' : ''">★</i>
                </span>
              </div>
              <div class="text-center mt-3">
                <button 
                  @click="submitGameReview(gameChosen['title'], gameReviewText, rateGameNumStarsSelected)" 
                  type="button" 
                  class="mt-3 mx-1 inline-flex justify-center items-center rounded-md border border-primary font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm p-2 ring-offset-gray-800">
                    Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- REVIEW GAME (ENDS) -->
       
      <FooterComponent />
    </div>
  </body>


</template>

<script>
import axios from 'axios';

import FooterComponent from './Layout/FooterComponent.vue';
import HeaderComponent from './Layout/HeaderComponent.vue';

// Axios configurations
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;


export default {
  name: 'GamePickerPage',
  data: function() {
    return {
      allGameReviews: [],
      gameChosen: null,
      gameReviewText: null,
      gameTipText: null,
      averageGameRating: null,
      loading: false,
      authenticated: false,
      user_id: null,
      userQuota: null,
      notificationPresent: false,
      rateGamePresent: false,
      rateGameNumStarsSelected: null,
      notification: '',
      notificationLinkPresent: false,
      notificationTimeoutId: null,
      isLargeScreen: false,
      reviewDisposition: null,
      sectionShowing: 'Reviews'
    }
  },
  props: {
    gameId: {
      type: String,
      required: true
    },
    gameTitle: {
      type: String,
      required: true
    }
  },
  components: {
    HeaderComponent,
    FooterComponent,
  },
  beforeMount: function() {
    this.initializeData();
  },
  methods: {
    initializeData: async function() {
      this.loading = true;

      // First we check the screen size
      await this.checkScreenSize();

      // Attempting to get user information in case they are logged in
      await this.getUserLoginStatus();

      // Setting up all the games from our dataset
      const allGames = await require('../assets/data/games-info-all.json');
      this.allGames = allGames['data'];

      const gamePicked = this.allGames.find(game => game['xbox_api_id'] === this.gameId);
      this.gameChosen = gamePicked;

      await this.getGameReviews(this.gameChosen['title']);

      this.loading = false;
    },
    voteReview: async function(reviewId, vote) {
      const inputData = {
        review_id: reviewId,
        vote: vote
      };

      try {
        const res = await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/vote-game-review/`, inputData,
          {
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': csrfToken
            },
            withCredentials: true,
          })

          // Check if res status is 200
          if (res.status === 200) {

            // We add a vote to the review score for the game
            let voteNum;
            if (vote) {
              voteNum = 1;
            } else {
              voteNum = -1;
            }
            const reviewIndex = this.allGameReviews.findIndex(review => review.id === reviewId);

            this.allGameReviews[reviewIndex].total_votes += voteNum;
            this.allGameReviews[reviewIndex].user_vote = res.data.vote_status;

            // If the user submitted a new review vote, we update their points
            const total_points_accumulated = res.data.total_points_accumulated;
            
            if (total_points_accumulated !== undefined) {

              await this.$store.dispatch(
                'setGameKarmaPoints', total_points_accumulated
              );
            }
          }

      } catch (error) {
        console.error('Could not submit the game review vote.', error);

        if (error.response.status === 409) {
          const msg = 'Vote not submitted. '
                    + 'You have already voted on this review.';
          
          this.notification = msg;
          this.notificationPresent = true;

          this.notificationTimeoutId = setTimeout(() => {
            this.notificationPresent = false;
          }, 3000);
        }
      }
    },
    makeDateFriendly(dateString) {
      const date = new Date(dateString);

      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // months are zero-based
      const day = date.getDate().toString().padStart(2, '0');
      const year = date.getFullYear();

      const formattedDate = `${month}/${day}/${year}`;
      return formattedDate;
    },
    validateSubmissionText: function(
      submissionType, gameSubmissionText, rateGameNumStarsSelected
    ) {

      let validReview = true;
      let invalidTextMessage = `This ${submissionType} needs to be fixed, please try again by fixing the following:\n`;

      // Check that gameSubmissionText is non null
      if (gameSubmissionText === null || gameSubmissionText === '') {
        validReview = false;
        invalidTextMessage += `You need to write a ${submissionType} for the game.\n`;
      }

      // Check that the rateGameNumStarsSelected is not null
      if (rateGameNumStarsSelected === null) {
        validReview = false;
        invalidTextMessage += 'You need to select a rating for the game.\n';
      }

      // Check for profanity or inappropriate language

      // TODO: To improve this code and import as library or use LLM
      // These words have been taken from the following source:
      // https://github.com/LDNOOBW/List-of-Dirty-Naughty-Obscene-and-Otherwise-Bad-Words/tree/master?tab=readme-ov-file
      const bannedWords = [
        '2g1c',
        '2 girls 1 cup',
        'acrotomophilia',
        'alabama hot pocket',
        'alaskan pipeline',
        'anal',
        'anilingus',
        'anus',
        'apeshit',
        'arsehole',
        'ass',
        'asshole',
        'assmunch',
        'auto erotic',
        'autoerotic',
        'babeland',
        'baby batter',
        'baby juice',
        'ball gag',
        'ball gravy',
        'ball kicking',
        'ball licking',
        'ball sack',
        'ball sucking',
        'bangbros',
        'bangbus',
        'bareback',
        'barely legal',
        'barenaked',
        'bastard',
        'bastardo',
        'bastinado',
        'bbw',
        'bdsm',
        'beaner',
        'beaners',
        'beaver cleaver',
        'beaver lips',
        'beastiality',
        'bestiality',
        'big black',
        'big breasts',
        'big knockers',
        'big tits',
        'bimbos',
        'birdlock',
        'bitch',
        'bitches',
        'black cock',
        'blonde action',
        'blonde on blonde action',
        'blowjob',
        'blow job',
        'blow your load',
        'blue waffle',
        'blumpkin',
        'bollocks',
        'bondage',
        'boner',
        'boob',
        'boobs',
        'booty call',
        'brown showers',
        'brunette action',
        'bukkake',
        'bulldyke',
        'bullet vibe',
        'bullshit',
        'bung hole',
        'bunghole',
        'busty',
        'butt',
        'buttcheeks',
        'butthole',
        'camel toe',
        'camgirl',
        'camslut',
        'camwhore',
        'carpet muncher',
        'carpetmuncher',
        'chocolate rosebuds',
        'cialis',
        'circlejerk',
        'cleveland steamer',
        'clit',
        'clitoris',
        'clover clamps',
        'clusterfuck',
        'cock',
        'cocks',
        'coprolagnia',
        'coprophilia',
        'cornhole',
        'coon',
        'coons',
        'creampie',
        'cuck',
        'cuckhold',
        'cum',
        'cumming',
        'cumshot',
        'cumshots',
        'cunnilingus',
        'cunt',
        'darkie',
        'date rape',
        'daterape',
        'deep throat',
        'deepthroat',
        'dendrophilia',
        'dick',
        'dildo',
        'dingleberry',
        'dingleberries',
        'dirty pillows',
        'dirty sanchez',
        'doggie style',
        'doggiestyle',
        'doggy style',
        'doggystyle',
        'dog style',
        'dolcett',
        'domination',
        'dominatrix',
        'dommes',
        'donkey punch',
        'double dong',
        'double penetration',
        'dp action',
        'dry hump',
        'dvda',
        'eat my ass',
        'ecchi',
        'ejaculation',
        'erotic',
        'erotism',
        'escort',
        'eunuch',
        'fag',
        'faggot',
        'fecal',
        'felch',
        'fellatio',
        'feltch',
        'female squirting',
        'femdom',
        'figging',
        'fingerbang',
        'fingering',
        'fisting',
        'foot fetish',
        'footjob',
        'frotting',
        'fuck',
        'fuck buttons',
        'fuckin',
        'fucking',
        'fucktards',
        'fudge packer',
        'fudgepacker',
        'futanari',
        'gangbang',
        'gang bang',
        'gay sex',
        'genitals',
        'giant cock',
        'girl on',
        'girl on top',
        'girls gone wild',
        'goatcx',
        'goatse',
        'god damn',
        'gokkun',
        'golden shower',
        'goodpoop',
        'goo girl',
        'goregasm',
        'grope',
        'group sex',
        'g-spot',
        'guro',
        'hand job',
        'handjob',
        'hard core',
        'hardcore',
        'hentai',
        'homoerotic',
        'honkey',
        'hooker',
        'horny',
        'hot carl',
        'hot chick',
        'how to kill',
        'how to murder',
        'huge fat',
        'humping',
        'incest',
        'intercourse',
        'jack off',
        'jail bait',
        'jailbait',
        'jelly donut',
        'jerk off',
        'jigaboo',
        'jiggaboo',
        'jiggerboo',
        'jizz',
        'juggs',
        'kike',
        'kinbaku',
        'kinkster',
        'kinky',
        'knobbing',
        'leather restraint',
        'leather straight jacket',
        'lemon party',
        'livesex',
        'lolita',
        'lovemaking',
        'make me come',
        'male squirting',
        'masturbate',
        'masturbating',
        'masturbation',
        'menage a trois',
        'milf',
        'missionary position',
        'mong',
        'motherfucker',
        'mound of venus',
        'mr hands',
        'muff diver',
        'muffdiving',
        'nambla',
        'nawashi',
        'negro',
        'neonazi',
        'nigga',
        'nigger',
        'nig nog',
        'nimphomania',
        'nipple',
        'nipples',
        'nsfw',
        'nsfw images',
        'nude',
        'nudity',
        'nutten',
        'nympho',
        'nymphomania',
        'octopussy',
        'omorashi',
        'one cup two girls',
        'one guy one jar',
        'orgasm',
        'orgy',
        'paedophile',
        'paki',
        'panties',
        'panty',
        'pedobear',
        'pedophile',
        'pegging',
        'penis',
        'phone sex',
        'piece of shit',
        'pikey',
        'pissing',
        'piss pig',
        'pisspig',
        'playboy',
        'pleasure chest',
        'pole smoker',
        'ponyplay',
        'poof',
        'poon',
        'poontang',
        'punany',
        'poop chute',
        'poopchute',
        'porn',
        'porno',
        'pornography',
        'prince albert piercing',
        'pthc',
        'pubes',
        'pussy',
        'queaf',
        'queef',
        'quim',
        'raghead',
        'raging boner',
        'rape',
        'raping',
        'rapist',
        'rectum',
        'reverse cowgirl',
        'rimjob',
        'rimming',
        'rosy palm',
        'rosy palm and her 5 sisters',
        'rusty trombone',
        'sadism',
        'santorum',
        'scat',
        'schlong',
        'scissoring',
        'semen',
        'sex',
        'sexcam',
        'sexo',
        'sexy',
        'sexual',
        'sexually',
        'sexuality',
        'shaved beaver',
        'shaved pussy',
        'shemale',
        'shibari',
        'shit',
        'shitblimp',
        'shitty',
        'shota',
        'shrimping',
        'skeet',
        'slanteye',
        'slut',
        's&m',
        'smut',
        'snatch',
        'snowballing',
        'sodomize',
        'sodomy',
        'spastic',
        'spic',
        'splooge',
        'splooge moose',
        'spooge',
        'spread legs',
        'spunk',
        'strap on',
        'strapon',
        'strappado',
        'strip club',
        'style doggy',
        'suck',
        'sucks',
        'suicide girls',
        'sultry women',
        'swastika',
        'swinger',
        'tainted love',
        'taste my',
        'tea bagging',
        'threesome',
        'throating',
        'thumbzilla',
        'tied up',
        'tight white',
        'tit',
        'tits',
        'titties',
        'titty',
        'tongue in a',
        'topless',
        'tosser',
        'towelhead',
        'tranny',
        'tribadism',
        'tub girl',
        'tubgirl',
        'tushy',
        'twat',
        'twink',
        'twinkie',
        'two girls one cup',
        'undressing',
        'upskirt',
        'urethra play',
        'urophilia',
        'vagina',
        'venus mound',
        'viagra',
        'vibrator',
        'violet wand',
        'vorarephilia',
        'voyeur',
        'voyeurweb',
        'voyuer',
        'vulva',
        'wank',
        'wetback',
        'wet dream',
        'white power',
        'whore',
        'worldsex',
        'wrapping men',
        'wrinkled starfish',
        'xx',
        'xxx',
        'yaoi',
        'yellow showers',
        'yiffy',
        'zoophilia',
        '🖕',
      ]; 

      const containsProfanity = bannedWords.some(word => 
        new RegExp(`\\b${word}\\b`, 'i').test(gameSubmissionText)
      );

      if (containsProfanity) {
        invalidTextMessage += `${this.capitalizeFirstLetter(submissionType)} contains inappropriate language.\n`
        validReview = false;
      }

      // Check for links
      const containsLinks = /(https?:\/\/[^\s]+)/g.test(gameSubmissionText);

      if (containsLinks) {
        invalidTextMessage += `${this.capitalizeFirstLetter(submissionType)} contains links.\n`
        validReview = false;
      }

      // Check for HTML or JavaScript content
      const containsHTMLorJS = /<\/?[a-z][\s\S]*>/i.test(gameSubmissionText);

      if (containsHTMLorJS) {
        invalidTextMessage += `${this.capitalizeFirstLetter(submissionType)} contains potential HTML or JavaScript content.\n`
        validReview = false;
      }

      // Check for SQL Injection patterns
      const sqlInjectionPatterns = [
        /(\b(SELECT|INSERT|UPDATE|DELETE|DROP|ALTER)\b\s*)/i, // SQL commands
        /(\b(OR|AND)\b\s*\d+=\d+)/i, // Conditional injection patterns
      ];

      const containsSQLInjection = sqlInjectionPatterns.some(pattern => pattern.test(gameSubmissionText));

      if (containsSQLInjection) {
        invalidTextMessage += `${this.capitalizeFirstLetter(submissionType)} contains potential SQL injection patterns.\n`;
        validReview = false;
      }

      // Check if the text is at least 
      if (gameSubmissionText !== null && gameSubmissionText.length < 85) {
        invalidTextMessage += `If you are going to write a ${submissionType}, make it count! Write at least a few sentences.\n`;
        validReview = false;
      }

      if (!validReview) {
        this.notification = invalidTextMessage;
        this.notificationPresent = true;
      }

      // Check if the text does not exceed 5,000 characters
      if (gameSubmissionText !== null && gameSubmissionText.length > 5000) {
        invalidTextMessage += `${this.capitalizeFirstLetter(submissionType)} exceeds the maximum character limit of 5,000.`;
        validReview = false;
      }

      if (!validReview) {
        this.notification = invalidTextMessage;
        this.notificationPresent = true;
      }

      return validReview;
    },
    submitGameReview: async function(gameTitle, gameReviewText, rateGameNumStarsSelected) {

      const validReview = this.validateSubmissionText(
        'review',
        gameReviewText,
        rateGameNumStarsSelected
      );

      if (!validReview) {
        return;
      }

      const inputData = {
        game_title: gameTitle,
        game_review_text: gameReviewText,
        rate_game_num_stars_selected: rateGameNumStarsSelected,
        review_disposition: this.reviewDisposition
      };

      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BACKEND_API_ROUTE}/submit-game-review/`, 
          inputData,
          {
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': csrfToken
            },
            withCredentials: true,
          })

          // Check if res status is 200
          if (res.status === 200) {

            // The user just submitted the review fresh
            if (this.reviewDisposition === 'New') {
              const user_game_review_id = res.data['user_game_review_id'];
              // We add the review to the list of reviews as the first review
              this.allGameReviews.unshift({
                'id': user_game_review_id,
                'user': this.$store.state.gamerId,
                'review_text': gameReviewText,
                'rating': rateGameNumStarsSelected,
                'total_votes': 0,
                'updated_on': new Date().toISOString()
              });

              // If the user submitted a new review, we update their points
              const total_points_accumulated = res.data.total_points_accumulated;
              
              if (total_points_accumulated !== undefined) {

                await this.$store.dispatch(
                  'setGameKarmaPoints', total_points_accumulated
                );
              }

            // The user is editing their own review
            } else if (this.reviewDisposition === 'Edit') {

              // We update the review in the list of reviews
              const reviewIndex = this.allGameReviews.findIndex(review => review.id === res.data['user_game_review_id']);
              this.allGameReviews[reviewIndex].review_text = gameReviewText;
              this.allGameReviews[reviewIndex].rating = rateGameNumStarsSelected;
              this.allGameReviews[reviewIndex].updated_on = new Date().toISOString();
            }
          }

      } catch (error) {
        console.error('Could not submit the game review.', error);

        if (error.response.status === 409) {
          const msg = 'Review not submitted. '
                    + 'You have already submitted a review for this game.';
          this.notification = msg;
          this.notificationPresent = true;

          this.notificationTimeoutId = setTimeout(() => {
            this.notificationPresent = false;
          }, 3000);
        }
      }

      // We reset things for another review
      this.rateGamePresent = false;
      this.gameReviewText = null;
      this.rateGameNumStarsSelected = null;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getGameReviews: async function(game_title) {
      this.loading = true;

      // If the user is not authenticated we send the user to the sign up page
      if (!this.authenticated) {
        this.navigateToSignUpPage();
        return;
      }

      this.sectionShowing = 'Reviews';

      // Make an API call to get the game reviews
      const averageGameRating = await this.getAverageGameRating(game_title);
      this.averageGameRating = averageGameRating;

      // Make an API call to get all the game reviews/commentary
      this.allGameReviews = await this.getThoroughGameReviews(game_title);

      this.loading = false;
    },
    openReviewGameModal: function(reviewInformation) {

      const gamerId = this.$store.state.gamerId

      // Check the user instance if they have a gamertag from state gamerId
      if (!gamerId) {
        this.notification = 'You need to have an Xbox GamerTag in order to submit a review.';
        this.notificationLinkPresent = true;
        this.notificationPresent = true;
        return;
      } else {

        if (reviewInformation) {
          // The user is editing their own review
          this.rateGameNumStarsSelected = reviewInformation['review_rating'];
          this.gameReviewText = reviewInformation['review_text'];
          this.reviewDisposition = 'Edit';
        } else {
          // The user is submitting a new review
          this.reviewDisposition = 'New';
        }

        this.rateGamePresent = true;
      }
    },
    getAverageGameRating: async function(game_title) {
      const inputData = {
        game_title: game_title
      };

      try {
        const res = await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/get-average-user-reviews-for-game/`, inputData,
          {
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': csrfToken
            },
            withCredentials: true,
          })

          return res.data['average_rating'];

      } catch (error) {
        console.error('Could not load the aggregated game reviews.', error);
      }
    },
    getThoroughGameReviews: async function(game_title) {
      const inputData = {
        game_title: game_title
      };

      try {
        const res = await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/get-all-game-reviews/`, inputData,
          {
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': csrfToken
            },
            withCredentials: true,
          })

          return res.data['all_game_reviews'];

      } catch (error) {
        console.error('Could not load the user game reviews.', error);
      }
    },
    getUserLoginStatus: async function () {
      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/user`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'  // Necessary for passing of login cookies from backend
        })

        const content = await response.json();

        if (content.detail === 'Unauthenticated!' || content.detail === 'Authentication credentials were not provided.') {
          this.authenticated = false;
          // Redirect the user to the sign up page
          this.navigateToSignUpPage();
        } else {
          this.authenticated = true;
          this.user_id = content.id;
        }
      } catch (e) {
        this.authenticated = false;
        // Redirect the user to the sign up page
        this.navigateToSignUpPage();

      }

    },
    navigateToSignUpPage: function() {
      this.$router.push('/signup');
    },
    generateGameDetailSlug: function (gameTitle) {
      return gameTitle
        .toLowerCase()                       // Convert to lowercase
        .replace(/[^a-z0-9\s-]/g, '')        // Remove invalid characters
        .trim()                              // Remove leading/trailing spaces
        .replace(/\s+/g, '-')                // Replace spaces with hyphens
        .replace(/-+/g, '-')                 // Remove duplicate hyphens
    },
    browseToGameDetails: async function (gameId, gameTitle) {
      const gameDetailSlug = await this.generateGameDetailSlug(gameTitle);
      this.$router.push(
        { 
          name: 'GameDetailsPage', 
          params: { gameId: gameId, gameTitle: gameDetailSlug } 
        }
      );
    },
    browseToGameTips: async function (gameId, gameTitle) {
      const gameDetailSlug = await this.generateGameDetailSlug(gameTitle);
      this.$router.push(
        { 
          name: 'GameTipsPage', 
          params: { gameId: gameId, gameTitle: gameDetailSlug } 
        }
      );
    },
    checkScreenSize() {
      this.isLargeScreen = window.innerWidth > 768; // Example breakpoint
    },
    clearExistingNotification() {
      if (this.notificationTimeoutId) {
        clearTimeout(this.notificationTimeoutId);
        this.notificationTimeoutId = null;
        this.notificationPresent = false;
        this.notificationLinkPresent = false;
        this.isPlayingOrPlayedGame = false;
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .game-list-btn-selector {
    border-top: 2px solid #42b983; 
    border-bottom: 2px solid #42b983; 
    border-left: 1px solid #42b983; 
    border-right: 1px solid #42b983; 
    color: inherit; 
  }

  .game-list-btn-selector-middle {
      border-left: 1px solid #42b983; 
      border-right: 1px solid #42b983;
      color: inherit; 
  }

  .game-list-btn-selector-left {
      border-left: 2px solid #42b983; 
      color: inherit; 
  }

  .game-list-btn-selector-right {
      border-right: 2px solid #42b983; 
      color: inherit; 
  }

  .game-list-btn-selector:hover {
      background-color: #42b983; 
  }

  .game-list-btn-selector:focus {
      background-color: #42b983; 
      outline: none; 
  }

  .hs-tooltip-content {
  transition: opacity 0.3s ease-in-out;
  }

  .hs-tooltip-content.opacity-0 {
    opacity: 0;
    visibility: hidden;
  }

  .hs-tooltip-content.opacity-100 {
    opacity: 1;
    visibility: visible;
  }

  .ign-score-container {
    position: relative;
    text-align: center;
    color: white;
  }

  .ign-score {
    position: absolute;
    font-weight: bolder;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -45%);
  }

  /* These styles are applied by default.
    They will also override the hover
    style for all stars to the right
    of the currently hovered star. */
  .rate i, .rate i:hover ~ i {
    color: gray;
    text-shadow: none;
    transition: color 200ms,
                text-shadow 200ms;
    /* This will remove the delay when
      moving the cursor left or right
      within the set of stars. */
    transition-delay: 0;
  }

  /* This is the style that will be
    applied to all stars and then
    subsequently removed from the stars
    to the right of the one being
    hovered. */
  .rate:hover i {
    color: #fc0;
    text-shadow: #fc0 0 0 20px;
  }

  /* Make the effect apply one star at a
    time. Limiting the selector to when
    .rate is hovered removes the effect
    without delay when cursor leaves
    the .rate area. */
  .rate:hover i:nth-child(2) {
    transition-delay: 30ms;
  }

  .rate:hover i:nth-child(3) {
    transition-delay: 60ms;
  }

  .rate:hover i:nth-child(4) {
    transition-delay: 90ms;
  }

  .rate:hover i:nth-child(5) {
    transition-delay: 120ms;
  }

  /* Miscellaneous styles. */
  .rate i {
    cursor: pointer;
    font-style: normal;
  }

  .rated i {
    color: gray;
    text-shadow: none;
    font-style: normal;
  }

  .starred {
    color: #fc0 !important;
    text-shadow: #fc0 0 0 20px !important;
  }

</style>
