<template>

  <body class="bg-slate-900 min-h-full">

    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">

      <HeaderComponent />

      <main v-if="!reccTypeChosen">
        <div class="text-xl font-bold mb-5">Game Recommendations</div>
        <div class="mx-5 mb-10 text-left">
          Get personalized and AI-based <span class="text-primary font-bold">
            game recommendations</span> and game suggestions
            <span class="text-primary font-bold">for you and your friends</span> 
            based on your gaming preferences, play history, and more.
          <br>
          <br>
          For the best recommendations, <span class="text-primary font-bold">
            rate games</span> in your game dashboard, 
          <span class="text-primary font-bold">add your Xbox GamerTag</span>, 
          and <span class="text-primary font-bold">sync your Xbox Games</span>. 
          The same applies to the friends you want to find games with. Aim for
          <font-awesome-icon class="text-primary px-1" icon="fa-solid fa-check" />
          instead of
          <font-awesome-icon class="text-red-500 px-1" icon="fa-solid fa-x" />.
        </div>
        <div class="inline-flex w-full">
          <button v-if="isLargeScreen" @click="browseToReccsSelf()" type="button" class="flex-1 h-24 mx-5 px-2 flex flex-col justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:text-green-500 hover:border-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
            <font-awesome-icon class="text-inherit text-lg" icon="fa-solid fa-user" />
            <span class="pt-2">Your Game Recs</span>
          </button>
          <button v-else @click="browseToReccsSelf()" type="button" class="flex-1 h-32 mx-5 px-2 flex flex-col justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:text-green-500 hover:border-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
            <font-awesome-icon class="text-inherit text-lg" icon="fa-solid fa-user" />
            <span class="pt-2">Your Game Recs</span>
          </button>
          <button v-if="isLargeScreen" @click="browseToReccsForFriends()" type="button" class="flex-1 h-24 mx-5 px-2 flex flex-col justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:text-green-500 hover:border-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
            <font-awesome-icon class="text-inherit text-lg" icon="fa-solid fa-users" />
            <span class="pt-1">Game Recs for You and Your Friends</span>
          </button>
          <button v-else @click="browseToReccsForFriends()" type="button" class="flex-1 h-32 mx-5 px-2 flex flex-col justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:text-green-500 hover:border-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
            <font-awesome-icon class="text-inherit text-lg" icon="fa-solid fa-users" />
            <span>Game Recs for You and Your Friends</span>
          </button>
          <button v-if="isLargeScreen" @click="browseToReccsFromFavoriteGames()" type="button" class="flex-1 h-24 mx-5 px-2 flex flex-col justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:text-green-500 hover:border-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
            <font-awesome-icon class="text-inherit text-lg" icon="fa-solid fa-heart" />
            <span class="pt-1">Similar Games to Your Favorite Game</span>
          </button>
          <button v-else @click="browseToReccsFromFavoriteGames()" type="button" class="flex-1 h-32 mx-5 px-2 flex flex-col justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:text-green-500 hover:border-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
            <font-awesome-icon class="text-inherit text-lg" icon="fa-solid fa-heart" />
            <span>Similar Games to Your Favorite Game</span>
          </button>
        </div>
      </main>

      <FooterComponent />
    </div>
  </body>


</template>

<script>

import { useHead } from '@vueuse/head';

import FooterComponent from '../components/Layout/FooterComponent.vue';
import HeaderComponent from '../components/Layout/HeaderComponent.vue';


export default {
  name: 'GamePickerPage',
  data: function() {
    return {
      isLargeScreen: false,
      loading: false,
      reccTypeChosen: false,
      reccType: null
    }
  },
  components: {
    HeaderComponent,
    FooterComponent,
  },
  beforeMount: async function() {
    this.loading = true;

    // First we check screen size
    await this.checkScreenSize();

    this.loading = false;
  },
  mounted() {
    useHead({
      title: 'Game Recommendations - Discover the Best GamePass Games for Every Playstyle',
      meta: [
        { 
          name: 'description', 
          content: 'Explore GamePass Picker’s game recommendations and find the best GamePass games tailored to your playstyle. Whether you’re gaming with friends, trying the latest GamePass releases, or looking for GamePass PC, Core or Ultimate games, we’ve got you covered. Dive into new adventures and connect with friends on GamePass today!' 
        },
        { 
          name: 'keywords', 
          content: 'best gamepass games, game recommendations, game suggestions, gaming with friends, gamepass new games, gamepass core games, gamepass pc games, gamepass ultimate games' 
        },
        { 
          property: 'og:title', 
          content: 'Top Game Recommendations on GamePass Picker - Find Your Next Game!' 
        },
        { 
          property: 'og:description', 
          content: 'Looking for your next favorite game? GamePass Picker’s game recommendations highlight the best GamePass games, from new releases to PC exclusives and multiplayer games with friends. Discover what’s new on GamePass and start playing today!' 
        },
      ],
    });
  },
  methods: {
    browseToReccsSelf: function() {
      this.$router.push('/recommendations-for-self');
    },
    browseToReccsForFriends: function() {
      this.$router.push('/recommendations-for-friends');
    },
    browseToReccsFromFavoriteGames: function() {
      this.$router.push('/recommendations-from-favorite-games');
    },
    checkScreenSize() {
      this.isLargeScreen = window.innerWidth > 768; // Example breakpoint
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }


</style>
