<script setup>
  import axios from 'axios';

  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';

  import HeaderComponent from '@/components/Layout/HeaderComponent.vue';
  import FooterComponent from '@/components/Layout/FooterComponent.vue';

  axios.defaults.withCredentials = true;

  const store = useStore();
  const loading = ref(true);

  const router = useRouter();

  const user = ref(null);
  const userQuota = ref(null);

  const userGameKarmaPoints = ref(null);
  const profileUrl = ref(null);


  onMounted(async () => {
    loading.value = true;

    // Retrieving the user
    try {
      const response = await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'  // Necessary for passing of login cookies from backend
      })

      // Check for 403 error
      if (response.status === 403) {
        await store.dispatch('setAuth', false);
        await navigateToLoginPage();
      }

      const content = await response.json();

      if (content.detail === 'Unauthenticated!') {
        await store.dispatch('setAuth', false);
        await navigateToLoginPage();
      } else {
        await store.dispatch('setAuth', true);
        user.value = content.user;
      }
    } catch (e) {
      await store.dispatch('setAuth', false);
      await navigateToLoginPage();
    }

    // We next get the user's points
    await getUserPoints();

    // Next we get check and reset the user's game picking quota
    // await checkAndResetSubscriptionQuota();

    // We next get the user's leftover quota
    await getUserSubscriptionQuota();

    // Creating the profile url the user has the gamertag set up
    if (user.value && user.value.xbox_gamertag) {
      profileUrl.value = `${window.location.origin}/social/profiles/${user.value.xbox_gamertag}`;
    }

    loading.value = false;
  })

  const authenticated = computed(() => store.state.authenticated);

  const navigateToLoginPage = () => {
      router.push('/login');
  }

  const navigateToGamePickerPage = () => {
      router.push('/');
  }

  // const navigateToPremiumPricingPage = () => {
  //   router.push('/premium-pricing');
  // }

  const showTooltip = async (event) => {
    const tooltip = event.currentTarget.querySelector('.hs-tooltip-content');
      tooltip.classList.remove('opacity-0', 'invisible');
      tooltip.classList.add('opacity-100', 'visible');
  }

  const hideTooltip = async (event) => {
    const tooltip = event.currentTarget.querySelector('.hs-tooltip-content');
        tooltip.classList.remove('opacity-100', 'visible');
        tooltip.classList.add('opacity-0', 'invisible');
  }

  // const checkAndResetSubscriptionQuota = async () => {

  //   // Initialize a time-zone aware date
  //   const userTimezoneDatetime = new Date().toISOString();

  //   const inputData = {
  //     user_tz_aware_current_datetime: userTimezoneDatetime
  //   };

  //   // Make the API call
  //   try {
  //     await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/check-and-reset-subscription-quota/`, inputData,
  //       {
  //         headers: {
  //             'Content-Type': 'application/json',
  //             // 'X-CSRFToken': csrfToken
  //         },
  //         withCredentials: true,
  //       })
  //   } catch (error) {
  //     console.error('Could not check and reset the subscription quota for the user.', error);
  //   }
  // }

  const getUserSubscriptionQuota = async () => {
    // Make the API call
    try {
      const response = await axios.get(`${process.env.VUE_APP_BACKEND_API_ROUTE}/get-user-subscription-quota/`,
        {
          headers: {
              'Content-Type': 'application/json',
              // 'X-CSRFToken': csrfToken
          },
          withCredentials: true,
        })

      if (response.data) {
        userQuota.value = response.data;
      }

    } catch (error) {
      console.error('Could not retrieve the subscription quota for the user.', error);
    }
  }

  const getUserPoints = async () => {
    // Make the API call
    try {
      const response = await axios.get(`${process.env.VUE_APP_BACKEND_API_ROUTE}/get-user-points/`,
        {
          headers: {
              'Content-Type': 'application/json',
              // 'X-CSRFToken': csrfToken
          },
          withCredentials: true,
        })

      if (response.data) {
        userGameKarmaPoints.value = response.data.points_balance;
      }

    } catch (error) {
      console.error('Could not retrieve the points for the user.', error);
    }
  }

  const addNewGamertag = async (gamerTag) => {
      if (!authenticated.value) {
          console.log('You must be logged in to add your gamer tag.');
          return;
      }

      // Define the payload data
      const inputData = {
          xbox_gamertag: gamerTag,
      };

      try {
          
          // Make the API call using await
          const response = await axios.post(
            `${process.env.VUE_APP_BACKEND_API_ROUTE}/add-new-xbox-gamertag/`, 
            inputData,
              {
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  withCredentials: true,
              });

            if (response.status === 200) {
              const gamerTagSuccess = response.data.xbox_gamertag;
              user.value.xbox_gamertag = gamerTagSuccess;
              profileUrl.value = `${window.location.origin}/social/profiles/${gamerTagSuccess}`;
            }

      } catch (error) {
          // Handle error
          console.error('We could not add your gamertag at this time', error);
      }
  }

  // const cancelMembership = async () => {
  //   loading.value = true;

  //   // Make the API call
  //   try {
  //     const response = await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/cancel-subscription/`,
  //       {
  //         headers: {
  //             'Content-Type': 'application/json',
  //             // 'X-CSRFToken': csrfToken
  //         },
  //         withCredentials: true,
  //       })

  //     if (response.status === 200) {
  //       // Next we get check and reset the user's game picking quota
  //       await checkAndResetSubscriptionQuota();

  //       // We next get the user's leftover quota
  //       await getUserSubscriptionQuota();
  //     }

  //     loading.value = false;

  //   } catch (error) {
  //     console.error('Could not cancel the user\'s subscription.', error);
  //     loading.value = false;
  //   }
  // }


  const logout = async () => {
      try {
      await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/logout`, {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json'
          },
          credentials: 'include'  // Necessary for passing of login cookies from backend
      });

      await store.dispatch('setAuth', false);
      authenticated.value = false;
      await navigateToGamePickerPage();

      } catch (e) {
          authenticated.value = false;
          console.log('Failed to logout')
          console.log(e);
      }
  }

</script>

<template>
  <body class="bg-slate-900 min-h-full">
    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">
      <HeaderComponent />
      <div>
        <div class="text-xl font-bold mb-10">Profile</div>
        <div v-if="!loading && authenticated" class="text-left w-3/6 mx-auto pl-4">
            <div>
                <div class="text-primary font-bold">
                  <span>GameKarma Points </span>
                  <div class="hs-tooltip inline-block text-white text-xs">
                    <button @mouseover="showTooltip" @mouseout="hideTooltip" type="button" class="hs-tooltip-toggle">
                      (What's this?)
                      <span ref="tooltipContent" class="hs-tooltip-content opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 text-left text-xs font-bold bg-secondary border-yellow-300" role="tooltip">
                        GameKarma Points - Our way of rewarding you for your contributions to the community.
                        You earn GameKarma points by rating games, reviewing games, and making the community better overall.
                        You can use these points to unlock premium features, rewards,
                        and later on - buy gear for your gaming avatar!
                      </span>
                    </button>
                  </div>
                </div>
                <div v-if="userGameKarmaPoints !== null">
                  <svg width="50" height="50" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <!-- Background circle -->
                    <circle cx="100" cy="100" r="90" fill="gold" stroke="orange" stroke-width="5" />

                    <!-- Inner circle -->
                    <circle cx="100" cy="100" r="70" fill="goldenrod" />

                    <!-- Placeholder text -->
                    <text x="100" y="105" font-family="Arial" font-weight="bold" font-size="60" fill="white" text-anchor="middle" dominant-baseline="middle">
                      {{ userGameKarmaPoints }}
                    </text>
                  </svg>
                </div>
            </div>

            <div>
                <div class="text-primary font-bold mt-3">Username / XB Gamertag</div>
                <div>
                    <div v-if="user.xbox_gamertag">
                      {{ user.xbox_gamertag }}
                      <span class="pl-2 text-white cursor-pointer" data-hs-overlay="#hs-add-gamertag-modal">
                        <font-awesome-icon :icon="['fas', 'edit']" />
                      </span>
                    </div>
                    <div v-else class="text-xs">
                      <button class="bg-transparent text-white px-1 py-1 mx-1 my-1 border border-white rounded" data-hs-overlay="#hs-add-gamertag-modal">
                        Add GamerTag
                      </button>
                    </div>
                </div>
            </div>

            <div>
                <div class="text-primary font-bold mt-3">Social Profile<span v-if="user.xbox_gamertag" class="text-white text-xs"> (Show Your Friends)</span></div>
                <div>
                    <span v-if="user.xbox_gamertag">
                      <router-link :to="`/social/profiles/${user.xbox_gamertag}`" class="font-medium hover:underline text-white break-all" active-class="active-link">
                        {{ profileUrl }}
                      </router-link>
                    </span>
                    <span v-else class="text-xs">You must add your Username / XB Gamertag first</span>
                </div>
            </div>

            <div class="mt-3">
                <div class="text-primary font-bold">Email</div>
                <div>{{ user.email }}</div>
            </div>
            <!-- <div class="mt-3">
                <div class="text-primary font-bold">Membership</div>
                <div v-if="userQuota['stripe_cancelled_final_working_date']">
                    <div class="font-bold underline">Premium</div>
                    <div>Your Premium Membership will be cancelled on {{ userQuota['stripe_cancelled_final_working_date'].split('T')[0] }}</div>
                </div>
                <div v-else-if="userQuota['subscription_type'] === 'free'">
                    <div>Free - <span class="text-xs font-bold">Upgrade to Premium for only $3/mo. and get Unlimited Quotas</span></div>
                    <button @click="navigateToPremiumPricingPage" type="button" class="my-3 mx-1 inline-flex justify-center items-center rounded-md border border-transparent font-semibold bg-primary text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm p-2 ring-offset-gray-800">
                        Upgrade to Premium
                    </button>
                </div>
                <div v-else-if="userQuota['subscription_type'] === 'basic'">
                    <div>Basic</div>
                    <button @click="cancelMembership" type="button" class="my-3 mx-1 inline-flex justify-center items-center rounded-md border border-primary font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm p-2 ring-offset-gray-800">
                        Cancel Membership
                    </button>
                </div>
            </div> -->
            <!-- <div class="mt-3">
                <div class="text-primary font-bold mb-2">Quotas</div>
                <div v-if="userQuota['subscription_type'] === 'free'">
                    <div class="ml-1 sm:text-base text-xs"> Preferences Picks on Picker Page: <span class="font-bold underline">{{ userQuota['gp_picking_preferences_quota'] }}</span></div>
                    <div class="ml-1 sm:text-base text-xs"> Preferences Picks on Search Page: <span class="font-bold underline">{{ userQuota['search_games_pick_preferences_quota'] }}</span></div>
                    <div class="ml-1 sm:text-base text-xs"> Game Additions: <span class="font-bold underline">{{ userQuota['game_addition_quota'] }}</span></div>
                    <div class="ml-1 sm:text-base text-xs"> AI Recommendation Uses: <span class="font-bold underline">{{ userQuota['ai_recommendation_quota'] }}</span></div>
                    <div class="ml-1 mt-2 text-xs">Your weekly quota replenishes on {{ userQuota['subscription_replenish_date'] }}</div>
                </div>
                <div v-else>
                    <div class="font-bold underline">Unlimited Quotas</div>
                </div>

            </div> -->
            <div class="text-center mt-3">
                <button @click="logout" type="button" class="my-3 mx-1 inline-flex justify-center items-center rounded-md border border-primary font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm p-2 ring-offset-gray-800">
                    Logout
                </button>
            </div>
        </div>
      </div>

      <!-- ADD GAMERTAG MODAL (STARTS) -->
      <div id="hs-add-gamertag-modal" class="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto flex justify-center items-center inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm">
        <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all sm:max-w-lg sm:w-full m-3">
            <div class="flex flex-col border shadow-sm rounded-xl bg-gray-800 border-gray-700 shadow-slate-700/[.7]">
            <div class="flex justify-between items-center py-3 px-4 border-b border-gray-700">
              <h3 class="font-bold text-white text-left">
                Add your Xbox GamerTag
              </h3>
            </div>
            <div class="m-4">
              <label for="gamerTag" class="block text-sm mb-2 text-white text-left">GamerTag</label>
              <input id="gamerTag" v-model="xboxGamerTag" type="text" class="py-3 px-4 block w-full rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-900 border-gray-700 text-gray-400">
            </div>
            <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t border-gray-700">
              <button @click.prevent="addNewGamertag(xboxGamerTag)" type="button" class="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border-primary font-medium bg-primary shadow-sm align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 transition-all text-sm bg-primary border-gray-700 text-white focus:ring-offset-gray-800" data-hs-overlay="#hs-add-gamertag-modal">
                Submit
              </button>
              <button type="button" class="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium shadow-sm align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 transition-all text-sm bg-slate-900 hover:bg-slate-800 border-gray-700 text-gray-400 hover:text-white focus:ring-offset-gray-800" data-hs-overlay="#hs-add-gamertag-modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- ADD GAMERTAG MODAL (ENDS) -->

      <FooterComponent />
    </div>
  </body>
</template>

<style scoped>

  .hs-tooltip .hs-tooltip-content {
    width: 300px; /* Fixed width */
    border: 1px solid gold; /* Gold border */
    border-radius: 5px; /* Rounded corners */
    color: white; /* White text */
    background-color: #111725; /* Adjust background color as needed */
    z-index: 100; /* Place the tooltip above other elements */
    transition: opacity 0.3s ease-in-out;
  }

  .hs-tooltip-content.opacity-0 {
    opacity: 0;
    visibility: hidden;
  }
  .hs-tooltip-content.opacity-100 {
    opacity: 1;
    visibility: visible;
  }

  .hs-tooltip-toggle {
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  }


</style>
