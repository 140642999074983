<template>
    <body class="bg-slate-900 min-h-full">
        <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">
            <HeaderComponent />
            
            <div class="text-white text-xl text-left mt-20 mx-6">
                <p class="font-bold">For Support</p> 
                Contact <a href="mailto:mario@gamepasspicker.com" 
                           class="font-italic underline hover:text-green-300"
                           target="_blank"
                           rel="noreferrer">mario@gamepasspicker.com</a>
            </div>

            <div class="mx-6 mt-20 text-xl text-left">
                If you love this project, consider supporting it by buying me a coffee here ☕️
            </div>
            <div class="text-center mt-3">
                <button
                    @click="$router.push('/buy-me-a-coffee')"
                    type="button"
                    class="my-3 mx-1 inline-flex justify-center items-center rounded-md border border-primary font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm p-2 ring-offset-gray-800"
                >
                    Buy Me a Coffee
                </button>
            </div>

            <div class="text-white text-xl text-left mt-20 mx-6 font-bold">The Journey</div>
            <div class="text-white text-xl text-left mt-2 mx-6">
                <p>Hey there 👋 - I created the game pass game picker because
                I love playing games but increasingly found myself in 
                analysis paralysis with so many options on the Xbox Game
                Pass. With the game picker, I often just let chance 
                dictate what I play or use the advanced search/filtering to
                find games that I like or even use the AI-Based game 
                recommendations based on my gaming habits.
                I hope it helps someone else out there!
                </p>
                <p class="pt-2 text-sm">~ Made with ❤️ by <a href="https://www.mihaisplace.com" 
                target="_blank"
                rel="noreferrer">Mihai Avram</a>
                </p>
            </div>
            <FooterComponent />
        </div>
    </body>
</template>

<script setup>
import { useHead } from '@vueuse/head';

import HeaderComponent from '@/components/Layout/HeaderComponent.vue';
import FooterComponent from '@/components/Layout/FooterComponent.vue';

useHead({
  title: 'About GamePass Picker - Discover the Best GamePass Games',
  meta: [
    { name: 'description', content: 'Explore top GamePass games, personalized recommendations, game reviews, and connect as well as compete with a community of gamers via your favorite games. GamePass Picker helps you find, review, and select the best games for your next gaming adventure!' },
    { name: 'keywords', content: 'GamePass games, best GamePass games, game recommendations, game randomizer, games leaving GamePass, game suggestions, random game selector, game community, gaming competitions, play with friends, GamePass new games' },
    { property: 'og:title', content: 'About GamePass Picker - Discover the Best GamePass Games' },
    { property: 'og:description', content: 'GamePass Picker offers personalized game suggestions, reviews, and a community of GamePass gamers. Find top games, discover new releases, and compete with friends!' },
  ],
});


</script>

<style scoped>
</style>