<template>
    <!-- Icon Blocks -->
    <hr class="pb-3">
    <div class="text-2xl font-bold mb-4 px-1">Join the Best Game Pass Companion App</div>
        <div class="max-w-[85rem] px-4 py-4 sm:px-6 lg:px-8 lg:py-4 mx-auto">
        <div v-if="isMobileScreen" class="text-left">
            The Game Pass Companion App offers a range of features such as the 
            ability to save game lists, explore/filter/rank games with detailed 
            metrics. Receive AI-based game recommendations, and get multiplayer 
            game suggestions to enjoy with friends while making new ones on 
            Discord. Rate/review games and check the aggregate reviews/tips
            from fellow gamers. Compete in contests, win prizes, and climb the 
            leaderboards! Plus, customizable gaming profiles are coming soon, 
            letting you showcase your unique style.
        </div>
        <div v-else class="grid sm:grid-cols-2 lg:grid-cols-3 items-center gap-12">

            <!-- Icon Block -->
            <div>
                <div class="inline-flex"><font-awesome-icon icon="fa-solid fa-heart" /></div>
                <div class="mt-5">
                    <h3 class="font-semibold text-white">Game Lists</h3>
                    <p class="mt-1 text-sm text-gray-400">Save games that you want to play later, ones that you are playing now, and ones that you complete</p>
                </div>
            </div>
            <!-- End Icon Block -->

            <!-- Icon Block -->
            <div>
                <div class="inline-flex"><font-awesome-icon icon="fa-solid fa-binoculars" /></div>
                <div class="mt-5">
                    <h3 class="font-semibold text-white">Explore Games</h3>
                    <p class="mt-1 text-sm text-gray-400">Find your favorite games based on detailed metrics such as genre, rating, length, cloud-gaming, cross-play, co-op, and much more</p>
                </div>
            </div>
            <!-- End Icon Block -->

            <!-- Icon Block -->
            <div>
                <div class="inline-flex"><font-awesome-icon icon="fa-solid fa-star" /></div>
                <div class="mt-5">
                    <h3 class="font-semibold text-white">Game Recommendations</h3>
                    <p class="mt-1 text-sm text-gray-400">Get AI-Based game recommendations based on your gaming habits and game ratings</p>
                </div>
            </div>
            <!-- End Icon Block -->

            <!-- Icon Block -->
            <div>
                <div class="inline-flex"><font-awesome-icon icon="fa-solid fa-users" /></div>
                <div class="mt-5">
                    <h3 class="font-semibold text-white">Fun With Friends</h3>
                    <p class="mt-1 text-sm text-gray-400">Get multiplayer game recommendations for you and your friends based on your shared interests, make more friends on Discord</p>
                </div>
            </div>
            <!-- End Icon Block -->

            <!-- Icon Block -->
            <div>
                <div class="inline-flex"><font-awesome-icon icon="fa-solid fa-trophy" /></div>
                <div class="mt-5">
                    <h3 class="font-semibold text-white">Contests, Prizes & Leaderboards</h3>
                    <p class="mt-1 text-sm text-gray-400">Challenge friends and gamers worldwide for epic prizes and ultimate bragging rights. Showcase your achievements and climb the leaderboards!</p>
                </div>
            </div>
            <!-- End Icon Block -->

            <!-- Icon Block -->
            <div>
                <div class="inline-flex"><font-awesome-icon icon="fa-solid fa-user" /></div>
                <div class="mt-5">
                    <h3 class="font-semibold text-white">Customizable Gaming Profiles</h3>
                    <p class="mt-1 text-sm text-gray-400">Coming soon...</p>
                </div>
            </div>
            <!-- End Icon Block -->

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isMobileScreen: window.innerWidth <= 768, // Initialize based on current window size for mobile screens
        };
    }
};
</script>


<style scoped>
</style>