<script setup>

  import { useHead } from '@vueuse/head';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { ref } from 'vue';

  import HeaderComponent from '@/components/Layout/HeaderComponent.vue';
  import FooterComponent from '@/components/Layout/FooterComponent.vue';
  import { onBeforeMount } from 'vue';

  const authenticated = ref(false);
  const router = useRouter();
  const store = useStore();

  useHead({
    title: 'GamePass Picker Community - Connect, Compete, and Find Gaming Friends!',
    meta: [
      { 
        name: 'description', 
        content: 'Join the GamePass Picker community on Discord to find gaming friends and connect with like-minded gamers! Participate in gaming competitions with rewards, track your scores on leaderboards, and enjoy gaming with friends. GamePass Picker is your hub for social gaming, competitions, and building a game community!' 
      },
      { 
        name: 'keywords', 
        content: 'find gaming friends, game community, GamePass community, Discord gaming community, gaming competitions, gaming with friends, gaming rewards, GamePass leaderboards, social gaming, connect with gamers, game scoreboards, multiplayer competitions' 
      },
      { 
        property: 'og:title', 
        content: 'GamePass Picker Community - Connect, Compete, and Find Gaming Friends!' 
      },
      { 
        property: 'og:description', 
        content: 'Join GamePass Picker’s community to find gaming friends, compete in events with rewards, and track your achievements on our leaderboards. Be part of a vibrant game community and enjoy gaming with friends!' 
      },
    ],
  });

  onBeforeMount(() => {
    checkIfUserIsAuthenticated();
  });

  const checkIfUserIsAuthenticated = () => {
    if (!store.state.authenticated) {
      authenticated.value = false;
    } else {
      authenticated.value = true;
    }
  }

  const navigateToUrl = (url) => {
    if (url) {
      window.open(url, '_blank');
    }
  }

  const navigateToSignUpPage = () => {
    store.dispatch('setIntent', '/social/leaderboards');
    router.push('/signup');
  }

  const navigateToLeaderboardsPage = () => {
    router.push('/social/leaderboards');
  }

</script>

<template>
  <body class="bg-slate-900 min-h-full">
    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">
      <HeaderComponent />
      <div>
        <div class="h1 text-xl font-bold mb-8"><h1>Social</h1></div>
        <div class="mx-5 mb-10 text-left">
          <p>Join the GamePass Picker <span class="text-primary font-bold">
            community on Discord</span> to <span class="text-primary font-bold">
              find gaming friends</span> and connect with like-minded gamers! 
              Participate in <span class="text-primary font-bold">gaming 
              competitions with rewards</span>, track your scores 
              on <span class="text-primary font-bold">leaderboards</span>, and 
              enjoy gaming with friends.
          </p>
        </div>
        
        <div class="inline-flex w-full">
          <button @click="navigateToUrl('https://discord.gg/tzU8HY4fTc')" type="button" class="flex-1 h-24 mx-5 px-2 flex flex-col justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:text-green-500 hover:border-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
            <a href="https://discord.gg/tzU8HY4fTc" class="flex items-center justify-center font-medium hover:underline" target="_blank" rel="noopener noreferrer">
              <img src="../assets/join-discord-icon.png" class="w-6 h-5" alt="Join Discord"/>
            </a>
            <span class="pt-2">Discord</span>
          </button>

          <button @click="router.push('/competitions')" type="button" class="flex-1 h-24 mx-5 px-2 flex flex-col justify-center items-center gap-1 rounded-md border border-gray-400 font-semibold text-white hover:text-green-500 hover:border-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
            <span class="text-lg hover:underline text-yellow-500"><font-awesome-icon :icon="['fas', 'trophy']" /></span>
            <span class="pt-1">Competitions</span>
          </button>

          <button @click="authenticated ? navigateToLeaderboardsPage() : navigateToSignUpPage()" type="button" class="flex-1 h-24 mx-5 px-2 flex flex-col justify-center items-center gap-1 rounded-md border border-gray-400 font-semibold text-white hover:text-green-500 hover:border-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
            <span class="text-lg hover:underline text-red-500"><font-awesome-icon :icon="['fas', 'ranking-star']"/></span>
            <span class="pt-1">Leaderboards</span>
          </button>
        </div>

      </div>
      <FooterComponent />
    </div>
  </body>
</template>

<style scoped>

</style>
