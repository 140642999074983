<template>
    <body class="bg-slate-900 min-h-full">
        <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">
            <HeaderComponent />
            
            <div class="mx-4">
                <h1 class="text-xl font-bold">How You Can Help</h1>

                <div class="mt-10 text-left">
                    <p class="my-3">I’ve poured <b>hundreds of hours into building this platform as a side project with little to no funding</b>-—driven by a deep passion to make it the best it can be.</p>
                    <p class="my-3"><b>Every bit of support helps keep</b> this platform thriving.</p>
                    <p class="my-3">If you’ve enjoyed the journey so far, consider fueling my next steps with a coffee. <b>Your support truly makes a difference!</b></p>
                </div>

                <div class="flex justify-center mt-10">
                    <a href="https://www.buymeacoffee.com/gamepicker" target="_blank" tabindex="-1">
                        <img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" class="h-14">
                    </a>
                </div>
            </div>


            <FooterComponent />
        </div>
    </body>
</template>

<script setup>

import { useHead } from '@vueuse/head';

import HeaderComponent from '@/components/Layout/HeaderComponent.vue';
import FooterComponent from '@/components/Layout/FooterComponent.vue';


useHead({
    title: 'Support Us - Fuel Game Recommendations, Reviews, Competitions, and Community on the GamePass!',  
    meta: [
        { 
        name: 'description', 
        content: 'Support GamePass Picker and help us continue to bring you the best game recommendations, reviews, and gaming community experiences. Your contributions enable us to improve, add new features, and create more content that enhances your gaming adventures. Consider buying us a coffee or supporting us through donations!' 
        },
        { 
        name: 'keywords', 
        content: 'support GamePass Picker, buy me a coffee, fund game reviews, help game community, support gaming site, gaming community donations, contribute to GamePass reviews, gaming support' 
        },
        { 
        property: 'og:title', 
        content: 'Support GamePass Picker - Fuel More Game Content!' 
        },
        { 
        property: 'og:description', 
        content: 'Your support helps us bring you even more personalized game suggestions, reviews, and community features. Help keep the GamePass Picker experience thriving and growing by contributing today!' 
        },
    ],
});

</script>

<style scoped>
</style>