<template>
    <footer class="mt-auto text-center text-primary py-5">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <p class="text-sm text-primary">
                <span><a href="/">Xbox Game Pass Game Picker @ 2024</a></span>
                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <span class="hover:underline">
                    <a href="https://www.termsfeed.com/live/eec778ba-cd9a-4355-841e-e69d7f2afe53" 
                        target="_blank"
                        rel="noreferrer">Privacy</a>
                </span>
                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <span class="hover:underline">
                    <a href="/about">About & Support</a>
                </span>
                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <span class="hover:underline">
                    <a href="/buy-me-a-coffee">Back Us</a>
                </span>
            </p>
        </div>
    </footer>
</template>

<style scoped>
</style>